import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'

export type AsyncThunkAPI<ResourceType, Arguments> = AsyncThunk<
    ResourceType,
    Arguments,
    Record<string, string>
>

type updateResource<ResourceType> = {
    id: string,
    resource: Partial<ResourceType>
}

export type PagedResource<ResourceType> = {
    res: ResourceType[]
    pagination?: { filtered: number, page: number, pageSize: number }
    order?: { order?: string, orderDirection?: 'asc' | 'desc' | undefined }
    count: number
}

export class ThunkGenerator<Resource> {
  // eslint-disable-next-line class-methods-use-this
  protected extractErrorMessage (error: unknown): string {
    if (error instanceof Error) return error.message
    return String(error)
  }

  generateGet (name: string, request: (id: string) => Promise<Resource>): AsyncThunkAPI<Resource, string> {
    const handler: AsyncThunkPayloadCreator<Resource, string> = async (id, { rejectWithValue }) => {
      try {
        return await request(id)
      } catch (err) {
        return rejectWithValue(this.extractErrorMessage(err))
      }
    }
    return createAsyncThunk(name, handler)
  }

  generateGetMany (name: string, request: (filters: Record<string, any>) => Promise<PagedResource<Resource>>): AsyncThunkAPI<PagedResource<Resource>, Record<string, any>> {
    const handler: AsyncThunkPayloadCreator<PagedResource<Resource>, Record<string, any>> = async (filters, { rejectWithValue }) => {
      try {
        return await request(filters)
      } catch (err) {
        return rejectWithValue(this.extractErrorMessage(err))
      }
    }
    return createAsyncThunk(name, handler)
  }

  generatePost (name: string, request: (payload: Resource) => Promise<Resource>): AsyncThunkAPI<Resource, Resource> {
    const handler: AsyncThunkPayloadCreator<Resource, Resource> = async (payload, { rejectWithValue }) => {
      try {
        return await request(payload)
      } catch (err) {
        return rejectWithValue(this.extractErrorMessage(err))
      }
    }
    return createAsyncThunk(name, handler)
  }

  generatePut (name: string, request: (payload: updateResource<Resource>) => Promise<Resource>): AsyncThunkAPI<Resource, updateResource<Resource>> {
    const handler: AsyncThunkPayloadCreator<Resource, updateResource<Resource>> = async (payload, { rejectWithValue }) => {
      try {
        return await request(payload)
      } catch (err) {
        return rejectWithValue(this.extractErrorMessage(err))
      }
    }
    return createAsyncThunk(name, handler)
  }

  generatePatch (name: string, request: (payload: updateResource<Resource>) => Promise<Resource>): AsyncThunkAPI<Resource, updateResource<Resource>> {
    const handler: AsyncThunkPayloadCreator<Resource, updateResource<Resource>> = async (payload, { rejectWithValue }) => {
      try {
        return await request(payload)
      } catch (err) {
        return rejectWithValue(this.extractErrorMessage(err))
      }
    }
    return createAsyncThunk(name, handler)
  }

  generateDelete (name: string, request: (id: string) => Promise<Resource>): AsyncThunkAPI<Resource, string> {
    const handler: AsyncThunkPayloadCreator<Resource, string> = async (id, { rejectWithValue }) => {
      try {
        return await request(id)
      } catch (err) {
        return rejectWithValue(this.extractErrorMessage(err))
      }
    }
    return createAsyncThunk(name, handler)
  }
}
