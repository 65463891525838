import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import {AsyncThunkAPI} from './ThunkGenerator'


export class GenericThunkGenerator {
  // eslint-disable-next-line class-methods-use-this
  protected extractErrorMessage (error: unknown): string {
    if (error instanceof Error) return error.message
    return String(error)
  }

  generate <Resource = any, Props = any> (
    name: string,
    request: (props: Props) => Promise<Resource>
  ): AsyncThunkAPI<Resource, Props> {
    const handler: AsyncThunkPayloadCreator<Resource, Props> = async (props, { rejectWithValue }) => {
      try {
        return await request(props)
      } catch (err) {
        return rejectWithValue(this.extractErrorMessage(err))
      }
    }
    return createAsyncThunk(name, handler)
  }
}
