import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import ProfileThunkReducers from '../api/profiles/ProfileThunkReducers'
import {ProfilesState} from '../types'

export const initialState: ProfilesState = {
  profiles: [],
  profile: null,
  error: undefined,
  loading: false,
  count: 0,
  pagination: {
    page: 1,
    pageSize: 10,
    filtered: 10
  },
  order: {
    order: 'createdAt',
    orderDirection: 'asc'
  },
  filters: {
    search: ''
  }
}

export const profileSlicer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setLoading: (state: ProfilesState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setProfileFilters: (state: ProfilesState, { payload }: PayloadAction<any>) => {
      state.filters = payload
    },
    setPaginationPage: (state: ProfilesState, { payload }: PayloadAction<number>) => {
      state.pagination.page = payload
    },
    setPaginationPageSize: (state: ProfilesState, { payload }: PayloadAction<number>) => {
      state.pagination.pageSize = payload
    },
    setOrderField: (state: ProfilesState, { payload }: PayloadAction<string>) => {
      state.order.order = payload
    },
    setOrderDirection: (state: ProfilesState, { payload }: PayloadAction<'asc' | 'desc' | undefined>) => {
      state.order.orderDirection = payload
    }
  },
  extraReducers: ProfileThunkReducers
})

export const {
  setLoading,
  setProfileFilters,
  setOrderField,
  setPaginationPageSize,
  setOrderDirection,
  setPaginationPage
} = profileSlicer.actions
export default profileSlicer.reducer
