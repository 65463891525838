import {List, ListSubheader} from '@mui/material'
import {INotification} from 'project-types'
import Notification from './Notification'
import Scrollbar from '../theme-components/scrollbar/Scrollbar'

type NotificationListProps = {
  oldNotifications: INotification[];
  newNotifications: INotification[];
  handleNotificationClick: (notification: INotification) => void;
}
export default function NotificationList (props: NotificationListProps) {
  const { oldNotifications, newNotifications, handleNotificationClick } = props
  const hasBoth = oldNotifications.length > 0 && newNotifications.length > 0
  const headerNew = <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
    New
  </ListSubheader>
  const headerBefore = <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
    Before that
  </ListSubheader>
  return <Scrollbar sx={{ height: { xs: 340, sm: 340 } }}>
    <List disablePadding subheader={hasBoth && headerNew}>
      {newNotifications.map((notification) => (
        <Notification key={notification.id} notification={notification} onClick={handleNotificationClick} />
      ))}
    </List>

    <List disablePadding subheader={hasBoth && headerBefore}>
      {oldNotifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          onClick={handleNotificationClick}
        />
      ))}
    </List>
  </Scrollbar>
}
