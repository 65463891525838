import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import Thunks from './ProfileThunks'
import {ProfilesState} from '../../types'

export default (builder: ActionReducerMapBuilder<ProfilesState>): void => {
  /**
   * GET ONE
   */
  builder
    .addCase(Thunks.get.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.get.fulfilled, (state, { payload }) => {
      state.profile = payload
      state.loading = false
    })
    .addCase(Thunks.get.rejected, (state, payload) => {
      console.log('Profile get failed', payload)
      state.loading = false
    })

  
  /**
     * GET MANY
     */
  builder
    .addCase(Thunks.getMany.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.getMany.fulfilled, (state, { payload }) => {
      state.profiles = payload.res
      state.count = payload.count
      // if (payload.pagination) state.pagination = payload.pagination
      // if (payload.order) state.order = payload.order
      state.loading = false
    })
    .addCase(Thunks.getMany.rejected, (state, payload) => {
      console.log('Profile get many failed', payload)
      state.loading = false
    })

}
