// Define a base service using createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import CONFIG from '../../config';

type ImageUploadResponse = {
  res: {
    id: string;
    name: string;
    contentType: string;
    size: number;
    url: string;
    createdAt: string;
  };
};

// Define the arguments for the image upload
interface ImageUploadArgs {
  fileName: string;
  file: File; // You can use File or Buffer depending on your environment
  workspaceId: string;
}

export const imagesApi = createApi({
  reducerPath: 'imagesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.storageApi,
    credentials: 'include',
  }),
  tagTypes: ['Images'],
  endpoints: (builder) => ({
    uploadImage: builder.mutation<ImageUploadResponse, ImageUploadArgs>({
      query: ({ fileName, file, workspaceId }) => {
        const formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('buffer', file); // The file being uploaded

        return {
          url: `/workspaces/${workspaceId}/images`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Images'], // Invalidate images cache after upload
    }),
    deleteImage: builder.mutation<void, { workspaceId: string; imageId: string }>({
      query: ({ workspaceId, imageId }) => ({
        url: `/workspaces/${workspaceId}/images/${imageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Images'],
    }),
  }),
});


// Auto-generated hooks for the operations
export const {
  useUploadImageMutation,
  useDeleteImageMutation,
} = imagesApi;
