import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'
// slices
import partiesReducer from './slices/partySlicer'
import authReducer from './slices/authSlicer'
import userReducer from './slices/userSlicer'
import attendeesReducer from './slices/attendeesSlicer'
import partyCommentReducer from './slices/partyCommentsSlicer'
import appReducer from './slices/appSlicer'
import gradientsReducer from './slices/gradientSlicer'
import locationReducer from './slices/locationSlicer'
import managePartiesReducer from './slices/managePartySlicer'
import profileReducers from './slices/profileSlicer'
import { notificationsApi } from './api/notifications/NotificationQuery'; // adjust the import path as necessary
import { statsApi } from './api/stats/StatsQuery';
import {imagesApi} from './api/images/ImagesQuery' // adjust the import path as necessary


// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  parties: partiesReducer,
  manageParties: managePartiesReducer,
  auth: authReducer,
  users: userReducer,
  attendees: attendeesReducer,
  partyComments: partyCommentReducer,
  app: appReducer,
  gradients: gradientsReducer,
  locations: locationReducer,
  profiles: profileReducers,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  [imagesApi.reducerPath]: imagesApi.reducer,
});

export default rootReducer;
