import {Stack, Typography} from '@mui/material'
import {RHFMultiCheckbox} from '../../theme-components/hook-form'

const FILTER_BOOZE_OPTIONS = [
  { label: 'Provided', value: 'true' },
  { label: 'Bring Your Own', value: 'false' },
];

export default function BoozeFilter () {
  return <Stack spacing={1}>
    <Typography variant="subtitle1"> Booze </Typography>
    <RHFMultiCheckbox name="booze" options={FILTER_BOOZE_OPTIONS} sx={{ width: 1 }} />
  </Stack>
}
