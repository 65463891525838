import { Webapp } from './types'

declare let process: {
    env: {
        REACT_APP_PRODUCTION: string
        REACT_APP_API_ENDPOINT: string
        REACT_APP_STORAGE_API_ENDPOINT: string
        REACT_APP_LOG_REMOTE_URL: string
        REACT_APP_LOG_LEVEL: Webapp.LogLevels
        REACT_APP_GTM_ID: string
        REACT_APP_FEEDBACK_URL: string
    }
}

const c: Webapp.Config = {
  api: process.env.REACT_APP_API_ENDPOINT,
  storageApi: process.env.REACT_APP_STORAGE_API_ENDPOINT,
  development: process.env.REACT_APP_PRODUCTION !== 'true',
  gtmID: process.env.REACT_APP_GTM_ID,
  feedbackUrl: process.env.REACT_APP_FEEDBACK_URL,
  log: {
    remoteUrl: process.env.REACT_APP_LOG_REMOTE_URL,
    level: process.env.REACT_APP_LOG_LEVEL
  }
}

export default c
