import {Gradient} from '../redux/types'

const colorArray = [
  'linear-gradient(to bottom right, #ad5389, #3c1053)',
  'linear-gradient(to bottom right, #a8c0ff, #3f2b96)',
  'linear-gradient(to bottom right, #4e54c8, #8f94fb);',
  'linear-gradient(to bottom right, #bc4e9c, #f80759)',
  'linear-gradient(to bottom right, #11998e, #38ef7d)',
  'linear-gradient(to bottom right, #c94b4b, #4b134f)',
  'linear-gradient(to bottom right, #00b09b, #96c93d)',
  'linear-gradient(to bottom right, #800080, #ffc0cb)',
  'linear-gradient(to bottom right, #fc4a1a, #f7b733)',
  'linear-gradient(to bottom right, #22c1c3, #fdbb2d)',
];

function getRandomArrayIndex(seed: string, arr: any[]): number {
  let hash = 0;
  // eslint-disable-next-line
  for (let i = 0; i < seed.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + seed.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return Math.abs(hash) % arr.length;
}

export function generateGradient (name: string): string {
  return colorArray[getRandomArrayIndex(JSON.stringify(name), colorArray)]
}

export function generateLinearGradient (colors: string[], orientation = 'to bottom right') {
  return `linear-gradient(${orientation}, ${colors.join(', ')})`
}

export function getRandomGradientId(colors: Gradient[]): string {
  const randomItem = colors[getRandomArrayIndex(`${Math.random()}`, colors)]
  return randomItem.id
}
