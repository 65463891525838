import {IParty, PartyAggregations} from 'project-types'
import ResourceBase from '../ResourceBase'
import {PagedResource} from '../ThunkGenerator'

type ResourceType = IParty
type ResourceResponse = {
    res: ResourceType[]
    count: number
}

export default class PartyResource extends ResourceBase<ResourceType, ResourceResponse> {
  private endpoint = '/parties'

  async get (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'GET',
      urlPath: `${this.endpoint}/${resourceID}`
    })
    return response.res[0]
  }

  async getMany (filters: Record<string, any>): Promise<PagedResource<ResourceType>> {
    const response = await this.request<PagedResource<ResourceType>>({
      method: 'GET',
      urlPath: `${this.endpoint}/`,
      queryParameters: PartyResource.constructQuery(filters)
    })
    return response
  }

  async explore (filters: Record<string, any>): Promise<PagedResource<ResourceType>> {
    const response = await this.request<PagedResource<ResourceType>>({
      method: 'GET',
      urlPath: `${this.endpoint}/explore`,
      queryParameters: PartyResource.constructQuery(filters)
    })
    return response
  }

  async post (resource: ResourceType): Promise<ResourceType> {
    const { ...body } = resource
    const response = await this.request<{ res: IParty }>({
      method: 'POST',
      urlPath: `${this.endpoint}`,
      body
    })
    return response.res
  }

  async patch (resourceID: string, resource: Partial<ResourceType>): Promise<ResourceType> {
    const { ...body } = resource
    const response = await this.request({
      method: 'PATCH',
      urlPath: `${this.endpoint}/${resourceID}`,
      body
    })
    return response.res[0]
  }

  async delete (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'DELETE',
      urlPath: `${this.endpoint}/${resourceID}`
    })
    return response.res[0]
  }

  async aggregations (filters: Record<string, any>): Promise<{ res: PartyAggregations }> {
    const response = await this.request<Promise<{ res: PartyAggregations }>>({
      method: 'GET',
      urlPath: `${this.endpoint}/aggregations`,
      queryParameters: PartyResource.constructQuery(filters)
    })
    return response
  }

  private static constructQuery(filters: Record<string, any>) {
    return {
      'page': filters.page,
      'pageSize': filters.pageSize,
      'status': filters.status,
      'fromDate': filters.startDate,
      'toDate': filters.endDate,
      'preferences.drinks': filters.booze,
      'budget.amount.from': filters.entrance && filters.entrance[0],
      'budget.amount.to': filters.entrance && filters.entrance[1],
      'music.genre': filters.music,
      // Search
      'search': filters.search,
      // Order
      'order': filters.order,
      'orderDirection': filters.orderDirection,
      'attendees': filters.attendees,
      // Ownership
      'userID': filters.userID,
      // GeoLocation
      'centerLng': filters.lng,
      'centerLat': filters.lat,
      'radius': filters.radius
    }
  }
}

