import { m, MotionProps } from 'framer-motion'
// @mui
import { Box, BoxProps } from '@mui/material'

type Props = BoxProps & MotionProps

interface TextAnimateProps extends Props {
    text: string;
    delay?: number
    duration?: number
    offsetMultiplier?: number
}

export default function TextLoading({ text, delay = 2, duration = 2, offsetMultiplier = 0.05 }: TextAnimateProps) {
  const EmptyLetter = () => <span style={{ visibility: 'hidden' }}>&nbsp;</span>;
  return (
    <m.div
      style={{ display: 'flex', overflow: 'hidden' }}
    >
      {text.split('').map((letter, index) => (
        <m.span
          key={index + letter}
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: [0, 1, 1, 0],
            y: [20, 0, 0, -20],
          }}
          transition={{
            duration,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'easeInOut',
            delay: index * offsetMultiplier,
            repeatDelay: delay,
          }}
        >
          {letter === ' ' ? <EmptyLetter/> : letter}
        </m.span>
      ))}
    </m.div>
  );
}
