import { createSlice } from '@reduxjs/toolkit'

import {GradientsState} from '../types'

export const initialState: GradientsState = {
  list: [
    { id: '1', name: '', colors: ['#ad5389', '#3c1053']},
    { id: '2', name: '', colors: ['#a8c0ff', '#3f2b96']},
    { id: '3', name: '', colors: ['#4e54c8', '#8f94fb']},
    { id: '4', name: '', colors: ['#bc4e9c', '#f80759']},
    { id: '5', name: '', colors: ['#11998e', '#38ef7d']},
    { id: '6', name: '', colors: ['#c94b4b', '#4b134f']},
    { id: '7', name: '', colors: ['#00b09b', '#96c93d']},
    { id: '8', name: '', colors: ['#800080', '#ffc0cb']},
    { id: '9', name: '', colors: ['#fc4a1a', '#f7b733']},
    { id: '10', name: '', colors: ['#22c1c3', '#fdbb2d']},
    { id: '11', name: "Royal Blue + Petrol", colors: ["#BBD2C5", "#536976", "#292E49"]},
  ],
}

export const gradientSlicer = createSlice({
  name: 'gradients',
  initialState,
  reducers: {},
})

// export const {} = gradientSlicer.actions
export default gradientSlicer.reducer
