import ResourceBase from '../ResourceBase'

type ResourceType = any
type ResourceResponse = {
  res: ResourceType[]
  count: number
}

export default class AuthResource extends ResourceBase<ResourceType, ResourceResponse> {
  private endpoint = '/auth'

  async googleLogin (): Promise<ResourceType> {
    const response = await this.request<{ url: string }>({
      method: 'GET',
      urlPath: `${this.endpoint}/login/google`
    })
    window.location.href = response.url
  }

  async logout (): Promise<ResourceType> {
    await this.request<{ url: string }>({
      method: 'GET',
      urlPath: `${this.endpoint}/logout`
    })
  }

  async impersonate (userID: string): Promise<ResourceType> {
    await this.request<{ url: string }>({
      method: 'GET',
      urlPath: `${this.endpoint}/impersonate`,
      queryParameters: {
        userID
      }
    })
  }
}

