// eslint-disable-next-line import/no-extraneous-dependencies
import {IParty, PartyAggregations} from 'project-types'
import {ThunkGenerator} from '../ThunkGenerator'
import PartyResource from './PartyResource'
import {GenericThunkGenerator} from '../GenericThunkGenerator'

const PartyThunks = new ThunkGenerator<IParty>()
const PartyThunksGeneric = new GenericThunkGenerator()
const resource = new PartyResource()

export default {
  get: PartyThunks.generateGet('ManageParties/get', (resourceID) => resource.get(resourceID)),
  getMany: PartyThunks.generateGetMany('ManageParties/getMany', (filters) => resource.getMany(filters)),
  save: PartyThunks.generatePost('ManageParties/post', (resourceData) => resource.post(resourceData)),
  update: PartyThunks.generatePatch('ManageParties/patch', (updatePayload) => resource.patch(updatePayload.id, updatePayload.resource)),
  delete: PartyThunks.generateDelete('ManageParties/delete', (partyId) => resource.delete(partyId)),
  aggregations: PartyThunksGeneric.generate<{ res: PartyAggregations }, { filters: any }>('ManageParties/aggregations', (filters) => resource.aggregations(filters)),
}
