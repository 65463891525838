import {IUser} from 'project-types'
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import Thunks from './UserThunks'
import {UsersState} from '../../types'

export default (builder: ActionReducerMapBuilder<UsersState>): void => {
  /**
   * GET MANY
   */
  builder
    .addCase(Thunks.get.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.get.fulfilled, (state, { payload }) => {
      state.user = payload
      state.loading = false
    })
    .addCase(Thunks.get.rejected, (state, payload) => {
      console.log('User get failed', payload)
      state.loading = false
    })

  
  /**
     * GET MANY
     */
  builder
    .addCase(Thunks.getMany.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.getMany.fulfilled, (state, { payload }) => {
      state.users = payload.res
      state.count = payload.count
      // if (payload.pagination) state.pagination = payload.pagination
      // if (payload.order) state.order = payload.order
      state.loading = false
    })
    .addCase(Thunks.getMany.rejected, (state, payload) => {
      console.log('User get many failed', payload)
      state.loading = false
    })

  /**
   * GET ATTENDANCE
   */
  builder
    .addCase(Thunks.getAttendance.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.getAttendance.fulfilled, (state, { payload }) => {
      state.attending = payload.res
    })
    .addCase(Thunks.getAttendance.rejected, (state, payload) => {
      state.loading = false
    })

  /**
     * SAVE
     */
  builder
    .addCase(Thunks.save.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.save.fulfilled, (state, { payload }) => {
      // state.parties.push(payload)
      // state.party = payload
    })
    .addCase(Thunks.save.rejected, (state, payload) => {
      state.loading = false
      console.log('User save failed', payload)
    })

  /**
     * UPDATE
     */
  builder
    .addCase(Thunks.update.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.update.fulfilled, (state, { payload }) => {
      state.users = state.users.map((user: IUser) => {
        if (user.id === payload.id) user = payload
        return user
      })
      state.loading = false
    })
    .addCase(Thunks.update.rejected, (state, payload) => {
      state.loading = false
      console.log('User update failed', payload)
    })

  /**
     * DELETE
     */
  builder
    .addCase(Thunks.delete.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.delete.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    .addCase(Thunks.delete.rejected, (state, payload) => {
      state.loading = false
      console.log('User delete failed', payload)
    })
}
