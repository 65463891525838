import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/theme-components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const DiscoverPartiesPage = Loadable(lazy(() => import('../pages/parties/DiscoverPartiesPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/users/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/users/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/admin/UsersListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/settings/UserAccountPage')));


// DASHBOARD: PARTY
export const PartyNewPostPage = Loadable(lazy(() => import('../pages/parties/NewPartyPage')));
export const PartyPage = Loadable(lazy(() => import('../pages/parties/PartyPage')));


// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/misc/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/misc/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/misc/Page404')));

export const ComingSoonPage = Loadable(lazy(() => import('../pages/misc/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/misc/MaintenancePage')));
