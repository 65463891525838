import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {AttendeesState} from '../types'
import AttendeeThunkReducers from '../api/attendees/AttendeeThunkReducers'

export const initialState: AttendeesState = {
  attendees: [],
  capacity: 0,
  attendance: 0,
  error: undefined,
  loading: false,
  count: 0,
  filteredUsersAttending: [],
  pagination: {
    page: 1,
    pageSize: 10,
    filtered: 10
  },
  filters: {
    search: ''
  }
}

export const attendeesSlicer = createSlice({
  name: 'attendees',
  initialState,
  reducers: {
    setLoading: (state: AttendeesState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setAttendeesFilters: (state: AttendeesState, { payload }: PayloadAction<AttendeesState['filters']>) => {
      state.filters = payload
    },
    setPaginationPage: (state: AttendeesState, { payload }: PayloadAction<number>) => {
      state.pagination.page = payload
    },
    setPaginationPageSize: (state: AttendeesState, { payload }: PayloadAction<number>) => {
      state.pagination.pageSize = payload
    }
  },
  extraReducers: AttendeeThunkReducers
})

export const {
  setLoading,
  setAttendeesFilters,
  setPaginationPageSize,
  setPaginationPage
} = attendeesSlicer.actions
export default attendeesSlicer.reducer
