import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {AppState} from '../types'

export const initialState: AppState = {
  navOpen: true,
}

export const appSlicer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setNavOpen: (state: AppState, { payload }: PayloadAction<boolean>) => {
      state.navOpen = payload
    },
  },
})

export const {
  setNavOpen,
} = appSlicer.actions
export default appSlicer.reducer
