// eslint-disable-next-line import/no-extraneous-dependencies
import {LocationAutocomplete, LocationDetails} from 'project-types'
import GeoResource from './GeoResource'
import {GenericThunkGenerator} from '../GenericThunkGenerator'

const GeoThunks = new GenericThunkGenerator()
const resource = new GeoResource()

export default {
  autocomplete: GeoThunks.generate<
    { res: LocationAutocomplete[] },
    { search: string; sessionToken: string }
  >('Locations/autocomplete', (props) => resource.autocomplete(props.search, props.sessionToken)),

  getDetails: GeoThunks.generate<
    { res: LocationDetails },
    { placeId: string }
  >('Locations/details', (props) => resource.getDetails(props.placeId)),
}
