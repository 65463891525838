import {IUser} from 'project-types'
import ResourceBase from '../ResourceBase'
import {PagedResource} from '../ThunkGenerator'

type ResourceType = IUser
type ResourceResponse = {
    res: ResourceType[]
    count: number
}

export default class UserResource extends ResourceBase<ResourceType, ResourceResponse> {
  private endpoint = '/users'

  async get (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'GET',
      urlPath: `${this.endpoint}/${resourceID}`
    })
    return response.res[0]
  }

  async account (): Promise<ResourceType> {
    const response = await this.request<{  res: ResourceType  }>({
      method: 'GET',
      urlPath: `${this.endpoint}/me`,
    })
    return response.res
  }

  async getMany (filters: Record<string, any>): Promise<PagedResource<ResourceType>> {
    const query = {
      'page': filters.page,
      'pageSize': filters.pageSize,
      'search': filters.search,
      // Order
      'order': filters.order,
      'orderDirection': filters.orderDirection,
      'meta': filters.meta,
    }
    const response = await this.request<PagedResource<ResourceType>>({
      method: 'GET',
      urlPath: `${this.endpoint}/`,
      queryParameters: query
    })
    return response
  }

  async post (resource: ResourceType): Promise<ResourceType> {
    const { ...body } = resource
    const response = await this.request<{ res: IUser }>({
      method: 'POST',
      urlPath: `${this.endpoint}`,
      body
    })
    return response.res
  }

  async patch (resourceID: number | string, resource: Partial<ResourceType>): Promise<ResourceType> {
    const { ...body } = resource
    const response = await this.request({
      method: 'PATCH',
      urlPath: `${this.endpoint}/${resourceID}`,
      body
    })
    return response.res[0]
  }

  async delete (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'DELETE',
      urlPath: `${this.endpoint}/${resourceID}`
    })
    return response.res[0]
  }

  async enable (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'PATCH',
      urlPath: `${this.endpoint}/${resourceID}/enable`
    })
    return response.res[0]
  }

  async updateLocation (resourceID: string, location: { lat: number, long: number }): Promise<ResourceType> {
    const response = await this.request({
      method: 'PATCH',
      urlPath: `${this.endpoint}/${resourceID}/location`,
      body: location as any
    })
    return response.res[0]
  }

  async updateLastSeenOn (resourceID: string, location: { lat: number, long: number }): Promise<ResourceType> {
    const response = await this.request({
      method: 'PATCH',
      urlPath: `${this.endpoint}/${resourceID}/lastSeenOn`,
      body: location as any
    })
    return response.res[0]
  }

  async disable (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'PATCH',
      urlPath: `${this.endpoint}/${resourceID}/disable`
    })
    return response.res[0]
  }

  async promote (resourceID: string, role: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'PATCH',
      urlPath: `${this.endpoint}/${resourceID}/promote/${role}`
    })
    return response.res[0]
  }
}

