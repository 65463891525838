import { createSlice } from '@reduxjs/toolkit'

import AuthThunkReducers from '../api/auth/AuthThunkReducers'

export const initialState: any = {}

export const authSlicer = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: AuthThunkReducers
})

export default authSlicer.reducer
