import { Helmet } from 'react-helmet-async';
import {useEffect, useState} from 'react';
// @mui
import {
  Card,
  Container,
} from '@mui/material';
import {IParty} from 'project-types'
import {useDispatch, useSelector} from 'react-redux'
// routes

import { PATH_DASHBOARD } from 'src/routes/paths';
import {AppDispatch, RootState} from '../../redux/store'
import PartyThunks from '../../redux/api/parties/PartyThunks'
import {PartyTable} from '../../components/parties'
import {useSettingsContext} from '../../components/theme-components/settings'
import CustomBreadcrumbs from '../../components/theme-components/custom-breadcrumbs'
import {useAuthContext} from '../../components/auth/contexts/useAuthContext'
import useResponsive from '../../hooks/useResponsive'
import ManagePartyThunks from '../../redux/api/parties/ManagePartyThunks'

export default function PartyListPage() {
  const { themeStretch } = useSettingsContext()
  const { user } = useAuthContext()
  const isMobile = useResponsive('down', 'sm');
  const dispatch = useDispatch<AppDispatch>()
  const { filters, pagination, order } = useSelector((state: RootState) => state.manageParties)
  const [selectedParty, setSelectedParty] = useState<IParty | null>(null)

  useEffect(() => {
    if (!user) return
    dispatch(ManagePartyThunks.getMany({ ...filters, ...pagination, ...order }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, pagination, order])

  return (
    <>
      <Helmet>
        <title> Party list | Born To Party</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'} sx={{
        height: { xs: 'calc(100vh - 500px)', sm: 'calc(100vh - 310px)' },
        marginBottom: '-100px' // this is to fix a weird overflow issue on discover page
      }}>
        <CustomBreadcrumbs
          heading="Parties"
          links={[
            { name: `Here you can find all the parties in the application`, href: PATH_DASHBOARD.root },
          ]}
        />
        <Card sx={{mb: 3, position: 'relative'}}>
          <PartyTable sx={{ height: { xs: 'calc(70vh - 270px)', sm: 'calc(70vh - 120px)' } }} dense={isMobile} />
        </Card>
      </Container>
    </>
  )
}
