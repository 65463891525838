// @mui
import { Stack, Typography } from '@mui/material'
// components
import Logo from '../../theme-components/logo'
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles'

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, title }: Props) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
          display: { xs: 'none', md: 'none' }
        }}
      />

      <StyledSection 
        sx={{
          backgroundImage: 'url(/assets/illustrations/hero.png)',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}>
        {/* <Typography color="text.disabled" sx={{ marginRight: '20px' }} variant="body2"> Photo by </Typography> */}
        {/* <Typography color="text.disabled" sx={{ marginRight: '20px' }} variant="h5"> P. Mallioris </Typography> */}
        {/* <Typography color="text.disabled" sx={{ marginRight: '20px', marginBottom: '20px' }} variant="body1"> @pmallior </Typography> */}
        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
