// eslint-disable-next-line import/no-extraneous-dependencies
import {IParty, IUser} from 'project-types'
import {PagedResource, ThunkGenerator} from '../ThunkGenerator'
import UserResource from './UserResource'
import {GenericThunkGenerator} from '../GenericThunkGenerator'
import PartyResource from '../parties/PartyResource'

const UserThunks = new ThunkGenerator<IUser>()
const resource = new UserResource()

const GenericThunks = new GenericThunkGenerator()
const partyResource = new PartyResource()

export default {
  get: UserThunks.generateGet('Users/get', (resourceID) => resource.get(resourceID)),
  getMany: UserThunks.generateGetMany('Users/getMany', (filters) => resource.getMany(filters)),
  save: UserThunks.generatePost('Users/post', (resourceData) => resource.post(resourceData)),
  update: UserThunks.generatePatch('Users/put', (updatePayload) => resource.patch(updatePayload.id, updatePayload.resource)),
  delete: UserThunks.generateDelete('Users/delete', (userID) => resource.delete(userID)),
  enable: UserThunks.generateGet('Users/enable', (resourceID) => resource.enable(resourceID)),
  disable: UserThunks.generateGet('Users/disable', (resourceID) => resource.disable(resourceID)),
  updateLocation: GenericThunks.generate<
    IUser,
    { id: string; location: { lat: number, long: number } }
  >('Users/updateLocation', (props) => resource.updateLocation(props.id, props.location)),
  updateLastSeenOn: GenericThunks.generate<
    IUser,
    { id: string; location: { lat: number, long: number } }
  >('Users/updateLocation', (props) => resource.updateLastSeenOn(props.id, props.location)),
  promote: GenericThunks.generate<
    IUser,
    { id: string; role: string }
  >('Users/promote', (props) => resource.promote(props.id, props.role)),
  getAttendance: GenericThunks.generate<
    PagedResource<IParty>,
    { attendees: string }
  >('Users/attendance', (props) => partyResource.getMany(props)),
}
