import {LocationAutocomplete, LocationDetails} from 'project-types'
import ResourceBase from '../ResourceBase'

type ResourceType = LocationAutocomplete
type ResourceResponse = {
    res: ResourceType[]
}

export default class GeoResource extends ResourceBase<ResourceType, ResourceResponse> {
  private endpoint = '/geo'

  async autocomplete (searchQuery: string, sessionToken: string): Promise<ResourceResponse> {
    const response = await this.request({
      method: 'GET',
      urlPath: `${this.endpoint}/autocomplete`,
      queryParameters: { search: searchQuery, sessionToken }
    })
    return response
  }

  async getDetails (placeId: string): Promise<{ res: LocationDetails }> {
    const response = await this.request<{ res: LocationDetails }>({
      method: 'GET',
      urlPath: `${this.endpoint}/details/${placeId}`,
    })
    return response
  }
}

