// @mui
import {Stack, Button, Typography, Box, Modal, IconButton, Card} from '@mui/material';
// auth
import React from 'react'
import {useTheme} from '@mui/material/styles'
import { useAuthContext } from '../../../auth/contexts/useAuthContext';
// locales
import { useLocales } from '../../../../locales';
// routes
import { PATH_DOCS } from '../../../../routes/paths';
import Iconify from '../../../theme-components/iconify'
import CONFIG from '../../../../redux/config'

// ----------------------------------------------------------------------

export default function NavDocs() {
  const { user, logout } = useAuthContext();
  const theme = useTheme()
  const { translate } = useLocales();
  const [open, setOpen] = React.useState(false);
  if (!user) return <div>Could not found user</div>;
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        component="img"
        src="/assets/illustrations/characters/character_7.png"
        width="80%"
      />

      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {`${translate('docs.description')}`}
        </Typography>
      </div>

      <Box sx={{ display: 'flex', gap: '10px'  }}>
        <Modal open={open} onClose={() => { setOpen(false) }}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '500px' },
            p: 2,
          }}>
            <Card sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              background: theme.palette.background.paper
            }}>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  color: 'white'
                }}
                onClick={() => {
                  setOpen(false)
                }}
              >
                <Iconify icon="mdi:close"/>
              </IconButton>
              <iframe id="kofiframe"
                src="https://ko-fi.com/borntoparty/?hidefeed=true&widget=true&embed=true&preview=true"
                style={{
                  border: 'none',
                  width: '100%',
                  padding: '4px',
                  background: '#f9f9f9',
                  height: '712px',
                }}
                title="borntoparty" />
            </Card>
          </Box>
        </Modal>
        <Button
          href=""
          rel="noopener"
          variant="contained"
          startIcon={
            <Iconify icon="iconoir:donate" color="white"/>
          }
          onClick={() => { setOpen(true) }}
        >
          {`${translate('docs.donate')}`}
        </Button>
        <Button
          href={CONFIG.feedbackUrl}
          target="_blank"
          rel="noopener"
          color='info'
          variant="text"
          startIcon={
            <Iconify sx={{ color: 'info' }} icon="fluent:person-feedback-16-regular" color="white" />
          }
        >
          {`${translate('docs.feedback')}`}
        </Button>
      </Box>
    </Stack>
  );
}
