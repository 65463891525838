// eslint-disable-next-line import/no-extraneous-dependencies
import {IAttendee} from 'project-types'
import { GenericThunkGenerator } from '../GenericThunkGenerator'
import AttendeeResource from './AttendeeResource'
import {PagedResource} from '../ThunkGenerator'

const AttendeeThunks = new GenericThunkGenerator()
const resource = new AttendeeResource()

export default {
  getMany: AttendeeThunks.generate<
        PagedResource<IAttendee>,
        { partyId: string, filters: Record<string, any> }
    >('Attendees/get', (props) => resource.getMany(props)),

  isUserAttending: AttendeeThunks.generate<
    PagedResource<IAttendee>,
    { partyId: string, userId?: string }
  >('Attendees/userAttending', (props) => resource.isUserAttending(props)),

  getAttendance: AttendeeThunks.generate<
    { res: { attendance: number, capacity: number } },
    { partyId: string }
  >('Attendees/count', (props) => resource.getAttendance(props)),

  update: AttendeeThunks.generate<
        IAttendee,
        { partyId: string, resource: Partial<IAttendee>[] }
    >('Attendees/update', (props) => resource.patch(props)),

  delete: AttendeeThunks.generate<
        IAttendee[],
        { partyId: string, resourceIDs: string[] }
    >('Attendees/delete', (props) => resource.delete(props)),
}
