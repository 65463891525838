// Define a base service using createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { INotification } from 'project-types';
import CONFIG from '../../config'

type NotificationsResponse = {
  res: INotification[];
  count: number;
};

interface NotificationQueryArgs {
  order?: string;
  orderDirection?: 'asc' | 'desc';
  read?: boolean;
  fromDate?: string;
  toDate?: string;
}

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api,
    credentials: 'include'
  }),
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    getNotifications: builder.query<NotificationsResponse, NotificationQueryArgs>({
      query: (args) => ({
        url: `/notifications`,
        params: {
          order: args?.order,
          orderDirection: args?.orderDirection,
          read: args?.read,
          'createdAt.from': args?.fromDate,
          'createdAt.to': args?.toDate,
        }
      }),
      providesTags: ['Notifications'],
    }),
    getUnreadNotifications: builder.query<NotificationsResponse, NotificationQueryArgs>({
      query: (args) => ({
        url: `/notifications`,
        params: {
          order: 'createdAt',
          orderDirection: 'desc',
          read: false,
          'createdAt.from': new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString(),
        }
      }),
      providesTags: ['Notifications'],
    }),
    getReadNotifications: builder.query<NotificationsResponse, NotificationQueryArgs>({
      query: (args) => ({
        url: `/notifications`,
        params: {
          order: 'createdAt',
          orderDirection: 'desc',
          read: true,
          'createdAt.from': new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
        }
      }),
      providesTags: ['Notifications'],
    }),
    getNotification: builder.query<INotification, string>({
      query: (id) => `/notifications/${id}`,
    }),
    markAsRead: builder.mutation<INotification, string>({
      query: (id) => ({
        url: `/notifications/${id}/mark-as-read`,
        method: 'POST',
      }),
      invalidatesTags: ['Notifications'],  // Invalidate the tag on mutation
    }),
    deleteNotification: builder.mutation<INotification, string>({
      query: (id) => ({
        url: `/notifications/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

// Auto-generated hooks for the operations
export const {
  useGetNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useGetReadNotificationsQuery,
  useGetNotificationQuery,
  useMarkAsReadMutation,
  useDeleteNotificationMutation,
} = notificationsApi;
