import { Navigate } from 'react-router-dom'
import {PATH_ADMIN, PATH_PAGE, PATH_PARTIES} from '../../../routes/paths'
import { useAuthContext } from '../contexts/useAuthContext'

// ----------------------------------------------------------------------

type UserRoleGuardProps = {
  children: React.ReactNode
};

export default function UserRoleGuard({ children }: UserRoleGuardProps) {
  const { isAuthenticated, user } = useAuthContext();
  if (isAuthenticated && user && user.role !== 'user') {
    if (user.role === 'admin') return <Navigate to={PATH_ADMIN.root} />
    return <Navigate to={PATH_PAGE.page403} />
  }
  return <> {children} </>
}
