import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {IPartyFilter, PartyCommentsState} from '../types'
import PartyCommentsThunkReducers from '../api/comments/PartyCommentsThunkReducers'

export const initialState: PartyCommentsState = {
  comment: null,
  comments: [],
  error: undefined,
  loading: false,
  count: 0,
  pagination: {
    page: 1,
    pageSize: 10,
    filtered: 10
  },
  order: {
    order: 'createdAt',
    orderDirection: 'asc'
  },
  filters: {
    booze: [],
    capacity: [],
    entrance: [],
    rating: '',
    startDate: null,
    endDate: null,
    search: ''
  }
}

export const partyCommentSlicer = createSlice({
  name: 'partyComments',
  initialState,
  reducers: {
    setLoading: (state: PartyCommentsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setPartyFilters: (state: PartyCommentsState, { payload }: PayloadAction<IPartyFilter>) => {
      state.filters = payload
    },
    setPaginationPage: (state: PartyCommentsState, { payload }: PayloadAction<number>) => {
      state.pagination.page = payload
    },
    setPaginationPageSize: (state: PartyCommentsState, { payload }: PayloadAction<number>) => {
      state.pagination.pageSize = payload
    },
    setOrderField: (state: PartyCommentsState, { payload }: PayloadAction<string>) => {
      state.order.order = payload
    },
    setOrderDirection: (state: PartyCommentsState, { payload }: PayloadAction<'asc' | 'desc' | undefined>) => {
      state.order.orderDirection = payload
    }
  },
  extraReducers: PartyCommentsThunkReducers
})

export const {
  setLoading,
  setPartyFilters,
  setOrderField,
  setPaginationPageSize,
  setOrderDirection,
  setPaginationPage
} = partyCommentSlicer.actions
export default partyCommentSlicer.reducer
