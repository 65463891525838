import {IParty} from 'project-types'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {Table, TableBody, TableContainer} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom, TableSelectedAction,
  useTable
} from '../../theme-components/table'
import PartyTableRow from './PartyTableRow'
import PartyTableToolbar from './PartyTableToolbar'
import {useDispatch, RootState} from '../../../redux/store'
import {
  setPaginationPageSize,
  setPaginationPage,
  setOrderField,
  setOrderDirection, setPartyFilters
} from '../../../redux/slices/managePartySlicer'
import {PATH_PARTIES} from '../../../routes/paths'
import ScrollbarAllDevices from '../../theme-components/scrollbar/ScrollbarAllDevices'
import {useSnackbar} from '../../theme-components/snackbar'
import {useAuthContext} from '../../auth/contexts/useAuthContext'
import ManagePartyThunks from '../../../redux/api/parties/ManagePartyThunks'
import AuthThunks from '../../../redux/api/auth/AuthThunks'

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left', sortable: true },
  { id: 'date', label: 'Date', align: 'left', width: 150, sortable: true },
  { id: 'status', label: 'Status', align: 'left', width: 50, sortable: true },
  { id: 'attendance', label: 'Attendance', align: 'center', width: 50, sortable: true },
  { id: 'preferences.capacity', label: 'Capacity', align: 'center', width: 50, sortable: true },
  { id: 'preferences.drinks', label: 'Drinks', align: 'center', width: 50, sortable: true },
  // { id: 'budget.amount', label: 'Damage', align: 'center', width: 50 },
  { id: '', align: 'center' },
];

type PartyTableProps = {
    sx?: any
    dense?: boolean
}

const PartyTable = (props: PartyTableProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { sx, dense = false } = props
  const { count, pagination, order, parties, filters } = useSelector((state: RootState) => state.manageParties)
  const { onSelectAllRows, onSelectRow, selected } = useTable({ defaultOrderBy: 'createdAt' });
  const [ openConfirm, setOpenConfirm ] = useState(false);

  const tableCount = count
  const page = pagination.page - 1
  const {pageSize} = pagination
  const partyCount = parties.length
  const orderDirection = order.orderDirection || 'asc'
  const orderBy = order.order

  const isNotFound = partyCount <= 0 // This is probably not right, and we need to defined when filters did not bring results

  const denseHeight = 76

  const onSort = (sortField: any) => {
    if (sortField === orderBy) {
      dispatch(setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc'))
    } else {
      dispatch(setOrderField(sortField))
      dispatch(setOrderDirection('asc'))
    }
  }
  const onChangePage = (event: any, pageN: any) => dispatch(setPaginationPage(pageN + 1))
  const onChangeRowsPerPage = (e: any) => {
    const outOfBounds = page * e.target.value > count
    if (outOfBounds) {
      dispatch(setPaginationPage(Math.floor(count / e.target.value)))
    }
    dispatch(setPaginationPageSize(e.target.value))
  }
  const handleDeleteRow = async (id: string) => {
    try {
      const deleteParty = await dispatch(ManagePartyThunks.delete(id))
      if (deleteParty.type === 'ManageParties/delete/rejected') {
        enqueueSnackbar('Failed to delete the party 😢', { variant: 'error' });
      } else {
        enqueueSnackbar('Party deleted');
        if (user) {
          const scopeFilters = user.role === 'admin' ? {} : { userID: user.id }
          dispatch(ManagePartyThunks.getMany({ ...filters, ...pagination, ...order, ...scopeFilters }))
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleDeleteRows = (selectedRows: string[]) => {}
  const handleEditRow = async (id: string) => {
    if (user?.role === 'admin') {
      await dispatch(AuthThunks.impersonate({
        userID: parties.find(party => party.id === id)?.userID || '',
      }))
      window.location.href = `${PATH_PARTIES.root}${id}`
    }
    navigate(`${PATH_PARTIES.root}${id}`)
  }
  const handleOpenConfirm = () => setOpenConfirm(true)

  return (
    <>
      <PartyTableToolbar
        search={filters.search} onSearch={(event) => {
          dispatch(setPaginationPage(1))
          dispatch(setPartyFilters({ ...filters, search: event.target.value }))
          dispatch(ManagePartyThunks.getMany({ ...filters, ...pagination, ...order }))
        }}
      />

      <TableContainer sx={{ position: 'relative', overflow: 'unset', ...sx }}>
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={parties.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              parties.map((party: IParty) => party.id)
            )
          }
          // action={
          //   <Tooltip title="Delete">
          //     <IconButton color="primary" onClick={handleOpenConfirm}>
          //       <Iconify icon="eva:trash-2-outline" />
          //     </IconButton>
          //   </Tooltip>
          // }
        />
        <ScrollbarAllDevices>
          <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
            <TableHeadCustom
              order={orderDirection}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={parties.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  parties.map((party: IParty) => party.id)
                )
              }
            />

            <TableBody>
              {parties
                .map((row) => (
                  <PartyTableRow
                    key={row.id}
                    row={row}
                    selected={selected.includes(row.id)}
                    onSelectRow={onSelectRow}
                    onDeleteRow={handleDeleteRow}
                    onEditRow={handleEditRow}
                  />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, pageSize, count)}
              />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </ScrollbarAllDevices>
      </TableContainer>

      <TablePaginationCustom
        count={tableCount}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={(event, pageN) => onChangePage && onChangePage(event, pageN)}
        onRowsPerPageChange={onChangeRowsPerPage}
        dense={dense}
      />
    </>
  )
}

export default PartyTable


