import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import Thunks from './AttendeeThunks'
import {AttendeesState} from '../../types'

export default (builder: ActionReducerMapBuilder<AttendeesState>): void => {
  /**
     * GET MANY
     */
  builder
    .addCase(Thunks.getMany.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.getMany.fulfilled, (state, { payload }) => {
      state.attendees = payload.res
      state.count = payload.count
      state.loading = false
    })
    .addCase(Thunks.getMany.rejected, (state, payload) => {
      state.loading = false
    })

  /**
   * IS USER ATTENDING
   */
  builder
    .addCase(Thunks.isUserAttending.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.isUserAttending.fulfilled, (state, { payload }) => {
      state.filteredUsersAttending = payload.res
      state.loading = false
    })
    .addCase(Thunks.isUserAttending.rejected, (state, payload) => {
      state.loading = false
    })

  /**
   * GET Count
   */
  builder
    .addCase(Thunks.getAttendance.pending, (state) => {
    })
    .addCase(Thunks.getAttendance.fulfilled, (state, { payload }) => {
      state.capacity = payload.res.capacity
      state.attendance = payload.res.attendance
    })
    .addCase(Thunks.getAttendance.rejected, (state, payload) => {
    })


  /**
     * UPDATE
     */
  builder
    .addCase(Thunks.update.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.update.fulfilled, (state, { payload }) => {
    })
    .addCase(Thunks.update.rejected, (state, payload) => {
      state.loading = false
    })

  /**
     * DELETE
     */
  builder
    .addCase(Thunks.delete.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.delete.fulfilled, (state, { payload }) => {
    })
    .addCase(Thunks.delete.rejected, (state, payload) => {
      state.loading = false
    })
}
