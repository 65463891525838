import ReactDOM from 'react-dom/client';
//
import ReactGA from 'react-ga4';
import { datadogRum } from '@datadog/browser-rum';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const DATADOG_ENABLED =
  process.env.REACT_APP_DATADOG_APPLICATION_ID !== undefined
  && process.env.REACT_APP_DATADOG_CLIENT_TOKEN !== undefined
  && process.env.REACT_APP_DATADOG_ENV !== undefined

if (DATADOG_ENABLED) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    env: process.env.REACT_APP_DATADOG_ENV as string,
    site: 'datadoghq.eu',
    service: 'born-to-party',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_COMMIT_ID as string,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

ReactGA.initialize(process.env.REACT_APP_GTM_ID as string);

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
