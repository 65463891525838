import {IProfile} from 'project-types'
import ResourceBase from '../ResourceBase'
import {PagedResource} from '../ThunkGenerator'

type ResourceType = IProfile
type ResourceResponse = {
    res: ResourceType[]
    count: number
}

export default class ProfileResource extends ResourceBase<ResourceType, ResourceResponse> {
  private endpoint = '/profiles'

  async get (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'GET',
      urlPath: `${this.endpoint}/${resourceID}`
    })
    return response.res[0]
  }

  async getMany (filters: Record<string, any>): Promise<PagedResource<ResourceType>> {
    const query = {
      'page': filters.page,
      'pageSize': filters.pageSize,
      'search': filters.search,
      // Order
      'order': filters.order,
      'orderDirection': filters.orderDirection,
    }
    const response = await this.request<PagedResource<ResourceType>>({
      method: 'GET',
      urlPath: `${this.endpoint}/`,
      queryParameters: query
    })
    return response
  }
}

