// routes
import {PATH_ADMIN, PATH_PARTIES, PATH_USERS} from '../../../../routes/paths';
// components
import Label from '../../../theme-components/label';
import Iconify from '../../../theme-components/iconify';
import SvgColor from '../../../theme-components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const iconPng = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  parties: icon('ic_parties'),
  list: iconPng('ic_list'),
  add: icon('ic_add'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Explore', path: PATH_PARTIES.find, icon: ICONS.parties },
      { title: 'Add Party', path: PATH_PARTIES.new, icon: ICONS.add },
      { title: 'Manage', path: PATH_PARTIES.list, icon: ICONS.list }
    ],
  }
];

const navByRole = (role: string) => {
  if (role === 'admin') return [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: '',
      items: [
        { title: 'User list', path: PATH_ADMIN.userList, icon: ICONS.user },
        { title: 'Party list', path: PATH_ADMIN.partyList, icon: ICONS.parties },
      ],
    }
  ]
  return navConfig
}

export default navConfig;
export {navByRole}
