import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import Thunks from './AuthThunks'

export default (builder: ActionReducerMapBuilder<any>): void => {
  /**
     * GET
     */
  builder
    .addCase(Thunks.googleLogin.pending, (state) => {
      // state.loading = true
      console.log(`Google login pending`)
    })
    .addCase(Thunks.googleLogin.fulfilled, (state, { payload }) => {
      console.log(`Google login finished`)
    })
    .addCase(Thunks.googleLogin.rejected, (state, payload) => {
      console.log(`Google login failed`)
    })
}
