import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import {useSelector} from '../../../redux/store'

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { navOpen } = useSelector((state) => state.app)
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const miniNav = isDesktop && !navOpen

  return (
    <>
      <Header onOpenNav={handleOpen} />
      <Box sx={{ display: { lg: 'flex' },  minHeight: { lg: 1 } }}>
        {
          miniNav
            ? <NavMini />
            : <NavVertical openNav={open} onCloseNav={handleClose} />
        }
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
