import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import UserThunkReducers from '../api/users/UserThunkReducers'
import {UsersState} from '../types'

export const initialState: UsersState = {
  users: [],
  user: null,
  attending: [],
  error: undefined,
  loading: false,
  count: 0,
  pagination: {
    page: 1,
    pageSize: 10,
    filtered: 10
  },
  order: {
    order: 'createdAt',
    orderDirection: 'asc'
  },
  filters: {
    search: '',
    meta: ['partyCount']
  }
}

export const userSlicer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setLoading: (state: UsersState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setUserFilters: (state: UsersState, { payload }: PayloadAction<any>) => {
      state.filters = payload
    },
    setPaginationPage: (state: UsersState, { payload }: PayloadAction<number>) => {
      state.pagination.page = payload
    },
    setPaginationPageSize: (state: UsersState, { payload }: PayloadAction<number>) => {
      state.pagination.pageSize = payload
    },
    setOrderField: (state: UsersState, { payload }: PayloadAction<string>) => {
      state.order.order = payload
    },
    setOrderDirection: (state: UsersState, { payload }: PayloadAction<'asc' | 'desc' | undefined>) => {
      state.order.orderDirection = payload
    }
  },
  extraReducers: UserThunkReducers
})

export const {
  setLoading,
  setUserFilters,
  setOrderField,
  setPaginationPageSize,
  setOrderDirection,
  setPaginationPage
} = userSlicer.actions
export default userSlicer.reducer
