import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const CustomListItem = ({ title, content }: { title: string; content: ReactNode }) => (
  <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
    <ListItemText
      primary={title}
      primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '18px' } }}
    />
    {content}
  </ListItem>
);

export const CustomListItemText = ({
  primary,
  secondary,
}: {
  primary?: string;
  secondary?: string | ReactNode;
}) => (
  <ListItem>
    <ListItemText
      primary={primary}
      primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
      secondary={secondary}
      secondaryTypographyProps={{ style: { color: 'white', fontSize: '16px' } }}
    />
  </ListItem>
);

export const CustomLink = ({ link, email }: { link: string; email?: boolean }) => (
  <Link color="inherit" underline="always" href={email ? `mailto:${link}` : link} target="_blank">
    {link}
  </Link>
);

// ----------------------------------------------------------------------

export default function TermsOfServices() {
  return (
    <>
      <Helmet>
        <title>Terms of Services | Born To Party</title>
      </Helmet>
      <Container sx={{ pt: 15, pb: 10, position: 'relative' }}>
        <Typography variant="h3" sx={{ mb: 5, color: 'primary.main' }}>
          TERMS OF USE
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'left' }}>
          The Visitor/User of the services on the website and/or the BORN2PARTY application hereby
          consents to the following terms of use, which apply to all content, pages, graphics,
          images, photographs, and any files included or uploaded on the website or the BORN2PARTY
          application. Therefore, they are required to carefully read these terms before visiting or
          using the pages and services of the website and/or the BORN2PARTY application. If they do
          not agree, then they must refrain from using the services and content of the BORN2PARTY
          application. The Visitor/Registered User is requested to check the content of these pages
          for any changes. Continuing to use the website and/or the BORN2PARTY application even
          after any changes signify the unconditional acceptance of these terms.
          <List sx={{ listStyle: 'decimal', pl: 4, mt: 5 }}>
            <CustomListItem
              title="Definitions"
              content={
                <CustomListItemText
                  secondary={
                    <>
                      <p>
                        - A &quot;<b>Visitor</b>&quot; is defined as a person who visits the
                        borntoparty.app website and/or the application without being a
                        &quot;User&quot; thereof.
                      </p>
                      <p>
                        - A &quot;<b>Registered User/User</b>&quot; is defined as a person who has
                        registered on the website and/or the BORN2PARTY application by entering the
                        required information and has access to all the functionalities of the
                        website and/or the BORN2PARTY application.
                      </p>
                      <p>
                        - An &quot;<b>Event Organizer</b>&quot; is an individual, sole
                        proprietorship, or legal entity that has created a personal
                        &quot;Profile&quot; on the website and/or the BORN2PARTY application and
                        represents themselves and/or their subcontractors regarding participation on
                        the website and/or the application.
                      </p>
                      <p>
                        - An &quot;<b>Event Seeker</b>&quot; is a &quot;Registered User&quot;
                        seeking an event.
                      </p>
                      <p>
                        - A &quot;<b>Personalized Page</b>&quot; is the section of the website
                        and/or the application created exclusively by Registered Users for sharing
                        an event.
                      </p>
                      <p>
                        - An &quot;<b>Event Creator</b>&quot; is the creator of the event.
                      </p>
                      <p>
                        - An &quot;<b>Attendee of the Event</b>&quot; is a person included in the
                        Attendee List of the Event and who has expressed their intention to
                        participate in the event.
                      </p>
                      <p>
                        - An &quot;<b>Attendee List of the Event</b>&quot; is the section of the
                        website and/or the application created exclusively by the Registered User
                        and includes the personal information of third parties who will attend an
                        Event.
                      </p>
                      <p>
                        - An &quot;<b>Account Profile</b>&quot; is the section of the website and/or
                        the application accessible exclusively by the Registered User.
                      </p>
                      <p>
                        - A &quot;<b>Search</b>&quot; is the section of the website and/or the
                        application where all active Events are displayed.
                      </p>
                      <p>
                        - An &quot;<b>Event Addition</b>&quot; is defined as the section of the
                        website and/or the application where the Registered User can submit an
                        upcoming Event. &quot;
                      </p>
                      <p>
                        - <b>&quot;Filters</b>&quot; refer to the section of the website and/or the
                        application where the registered User configures the search criteria for
                        Events.
                      </p>
                      <p>
                        - An &quot;<b>Event Management</b>&quot; is the section of the website
                        and/or the application where the Registered User has access to the Events
                        they have shown interest in.
                      </p>
                      <p>
                        - A &quot;<b>Donation</b>&quot; is the section of the website and/or the
                        application where the Registered User has the option to make a donation to
                        the website and/or the application.
                      </p>
                      <p>
                        - A &quot;<b>Feedback</b>&quot; is the section of the website and/or the
                        application where the registered User can provide feedback on the website
                        and/or the application.
                      </p>
                      <p>
                        - A &quot;<b>Link</b>&quot; is an electronic address of a website located
                        outside the borntoparty.app website.
                      </p>
                      <p>
                        - &quot;<b>Website</b>&quot; or &quot;<b>Site</b>&quot; or &quot;
                        <b>BORN2PARTY website</b>&quot; or &quot;<b>BORN2PARTY site</b>&quot; refers
                        to the website: borntoparty.app
                      </p>
                      <p>
                        - &quot;<b>Application</b>&quot; refers to the BORN2PARTY application.
                      </p>
                    </>
                  }
                />
              }
            />
            <CustomListItem
              title="Terms of Registered Users"
              content={
                <>
                  <CustomListItemText
                    primary="2.1. Registration - Account Creation"
                    secondary={
                      <p>
                        Only Registered Users have the ability to use the full range of services of
                        the website and/or the application. Any User wishing to register may create
                        their personal account using their Google account login credentials. The
                        website reserves the discretion, without any liability, to reject account
                        creation requests. You can view the{' '}
                        <Link
                          to="/privacy-policy"
                          component={RouterLink}
                          underline="always"
                          color="inherit"
                        >
                          Privacy Policy
                        </Link>{' '}
                        to understand what information we collect during your registration process
                        and how we use it. The User is solely responsible for maintaining the
                        security of their account. They are fully responsible for any action or
                        activity taken on the account or through it, and the website and/or
                        application bear absolutely no responsibility.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="2.2. Event Creation"
                    secondary={
                      <>
                        <p>
                          The Registered User may create an event by completing the event details
                          and posting the information about its conduct.
                        </p>
                        <p>
                          <b>2.2.1.</b> The event page provides
                          <b>Registered Users - Event Organizers</b>
                          with the following capabilities: a) posting the name of the event b)
                          posting the date and time of the event c) posting a text related to the
                          event d) posting details of the event venue e) posting a map of the event
                          venue f) posting information about the type of music at the event g)
                          posting information about the provision or non-provision of alcoholic or
                          non-alcoholic beverages.
                        </p>
                        <p>
                          <b>2.2.2.</b> The event page provides Registered
                          Users/Applicants-Requesters with the following capabilities: a) reviewing
                          the event description b) reviewing the attendees of the event c) selecting
                          interest declaration for their attendance at the event d) choosing to post
                          a comment about the event e) selecting to share the event f) the ability
                          to view the personalized website of the event creator.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="2.3. Creation of a Personalized Website"
                    secondary={
                      <p>
                        The Registered User may create and customize a personalized website, the
                        link to which they can share. The personalized website provides the
                        Registered User with the following capabilities: a) posting a photo, name,
                        text b) posting information about the events they have attended or will
                        attend or have created. Any recipient of the link can visit a personalized
                        website.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="2.4. Content of the Event Page and Personalized Website"
                    secondary={
                      <>
                        <p>
                          <b>2.4.1.</b> The creator of the event page and the personalized website
                          is solely responsible for their use by any visitor, registered User, or
                          third party. The BORN2PARTY website bears no responsibility for the
                          security of the above or for the information contained therein, including
                          photos, links, texts, and messages of any kind posted by the creator or
                          other registered Users. The availability and use of the above are subject
                          to all the present general and special terms of use of the BORN2PARTY
                          website. The BORN2PARTY website reserves the right at any time not to
                          allow the publication or posting of any photo, link, text, or message
                          selected by the registered User or to delete them. Non-use of this
                          discretion does not make the BORN2PARTY website responsible for the
                          content of the personalized page and for any violation of the present
                          terms of use.
                        </p>
                        <p>
                          <b>2.4.2.</b> While registered Users of the BORN2PARTY website are obliged
                          to provide accurate personal information, the BORN2PARTY website is not
                          obligated to ensure the correctness of this information.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="2.5. Sending - Posting Messages on the Event Page"
                    secondary={
                      <p>
                        The process of sending/posting messages through the event page is governed
                        by the terms herein. If any registered User does not agree with these terms,
                        please do not use this particular service. Messages are not directly subject
                        to content control by the BORN2PARTY website as they are sent without prior
                        content review and processing, and therefore do not necessarily reflect the
                        views or beliefs of the BORN2PARTY website. Therefore, the BORN2PARTY
                        website assumes no responsibility and is not liable for any problems arising
                        from communications via messages containing errors, omissions, or
                        inaccuracies, forgery, tampering, or containing offensive terms, malicious
                        defamation, deliberate or negligent behavior.
                      </p>
                    }
                  />
                </>
              }
            />
            <CustomListItem
              title="Terms of Registered Users"
              content={
                <>
                  <CustomListItemText
                    primary="3.1 Posting of Photos and Links by Users"
                    secondary={
                      <>
                        <p>
                          <b>3.1.1.</b> The website BORN2PARTY allows registered Users to post
                          photos. The User declares and guarantees: 1. That they are the creator and
                          have all related intellectual property rights and any other rights on each
                          posted photo they post or store on the website and on each provided
                          service thereof, or that they have obtained the relevant intellectual
                          property rights and any other rights on the posted photo. They also
                          declare that the photos do not depict minors under the age of 14 and that
                          they have obtained the explicit and unconditional consent of all
                          individuals depicted in the works and the owners of the spaces or animals
                          depicted in them to grant their relevant rights to the extent necessary
                          for the purposes of this. That in any case, they are solely and
                          exclusively responsible to the website BORN2PARTY in case of breach of the
                          above guarantees, and they exclusively undertake any liability in case of
                          any claim by any third party in connection with the use of the website
                          BORN2PARTY and its services or arising therefrom (including any claims
                          regarding rights to the photo(s), personality rights, etc.). That each
                          photo constitutes the intellectual property of its creator.
                        </p>
                        <p>
                          <b>3.1.2.</b> The website BORN2PARTY allows Users to post links to
                          third-party services/websites. Advertising of products or services or
                          posting advertising links of products or services is not allowed. Posting
                          links to third-party services/websites is the responsibility of the User.
                          The User bears sole responsibility for the content of the service/website
                          to which the link posted on the website&apos;s services refers, and
                          ensures that this content does not violate the terms of use of the website
                          BORN2PARTY. The website is not responsible for any loss or damage to the
                          User or third party related to the posting and use of links to third-party
                          services/websites.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="3.2. Information and Data Provided or Published by the User"
                    secondary={
                      <>
                        <p>
                          <b>3.2.1.</b> The User is solely responsible for the information, data,
                          and overall content they provide or publish on the website (hereinafter
                          collectively referred to as &quot;Information&quot;), and acknowledges
                          that the liability for any Information entered by them and available in
                          any way on the website rests solely with them personally. The User agrees
                          and undertakes that this information is not false, inaccurate, misleading,
                          offensive, threatening, defamatory, malicious, that it does not contain
                          pornographic, defamatory, misleading, obscene material, and in general
                          material that violates the rights provided by current legislation to
                          consumer public, other users, and this website, especially those
                          concerning the protection of human dignity, honor, and reputation,
                          privacy, minority, and professional reputation, personal data protection
                          legislation, and that it is accurate, up-to-date, and complete.
                        </p>
                        <p>
                          <b>3.2.2.</b> The User undertakes to ensure that the Information does not
                          infringe any intellectual property or any patent, trademark, trade secret,
                          or other rights, or any advertising, privacy, or proprietary rights, is
                          not malicious, or violates any laws or regulations such as, but not
                          limited to, unfair competition, discriminatory treatment, and false
                          advertising.
                        </p>
                        <p>
                          <b>3.2.3.</b>
                          The User must ensure that the Information does not create any form of
                          legal liability on the website or cause loss (partially or entirely) to
                          its users. The information published should not directly or indirectly
                          refer to any other website outside of the BORN2PARTY website.
                        </p>
                        <p>
                          <b>3.2.4.</b> The User confirms that the Information does not contain any
                          computer viruses, malware, trojans, worms, or anything else that
                          interferes with, interrupts, or disrupts the normal functioning of an
                          electronic computer and that they will not result in the interruption of
                          the smooth operation of the website and the Services it provides.
                        </p>
                        <p>
                          <b>3.2.5.</b> The User grants to the BORN2PARTY website a non-binding,
                          universal, continuous, irrevocable, royalty-free, and sub-licensable
                          license on the Information posted on the website, so that it meets the
                          requirements for the use of this Information concerning, but not limited
                          to, intellectual property rights, trademarks, advertisements, or databases
                          related to this Information.
                        </p>
                        <p>
                          <b>3.2.6.</b> The User acknowledges that the website has the absolute
                          right to process, modify, or withdraw any part or parts of the Information
                          posted by the User if it deems it necessary.
                        </p>
                        <p>
                          <b>3.2.7.</b> The BORN2PARTY website does not guarantee that the
                          information transmitted by Users through any form of communication, such
                          as posting text, messages, using message boards, is accurate, verified,
                          timely, understandable, or complete. It is possible that this information
                          may contain inaccuracies, typographical errors, due to human or electronic
                          oversight. The website is not responsible, under any circumstances, for
                          any direct, indirect, or consequential damages arising from the use of the
                          website or its services.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="3.3. Message Boards"
                    secondary={
                      <p>
                        The BORN2PARTY website maintains chat service areas on the Internet
                        according to its specific terms. Users of the relevant services must adhere
                        to the rules of good conduct and decency and not engage in illegal or
                        unethical statements. The BORN2PARTY website reserves the right to prohibit
                        or terminate the display of written messages or text postings in case of
                        violation of the above terms. The BORN2PARTY website cannot in any way be
                        deemed to accept or endorse the personal ideas or perceptions expressed in
                        these areas. Users of the relevant services retain the right to intellectual
                        property in their opinions expressed in their messages. The BORN2PARTY
                        website reserves the right to exclude or expel any User of the relevant
                        services in case of violation of the above terms. The use of the chat wall
                        is governed by the terms of use of the BORN2PARTY website. The registered
                        User using the chat wall service accepts that the username registered during
                        registration on the BORN2PARTY website and the messages posted on the chat
                        wall are accessible to all users of that specific chat wall. The registered
                        User accepts that the messages posted on the chat walls cannot be
                        permanently deleted from the database and pages of the BORN2PARTY website in
                        case of deletion/deactivation of their account. Therefore, they should be
                        particularly careful with the content of the messages they post on the chat
                        wall, or other information they disclose through it, as they may want to
                        deactivate their account in the future. User requests for the deletion of
                        messages and information posted on chat walls will only be accepted in
                        special cases and if there is an extremely serious reason, which will be
                        adequately justified by the respective User, but this remains at the
                        discretion of the management team of the BORN2PARTY website. You can send
                        your request to the email address info@borntoparty.app, clearly specifying
                        the information you want to delete.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.4. Liability for Third-Party Content - Links from the Website to Content on Other Pages"
                    secondary={
                      <p>
                        The BORN2PARTY website may contain hyperlinks or advertising banners linking
                        to other websites for information provided by third parties. Referral to
                        other websites is for the convenience of the public and in no way creates
                        any commitment by the BORN2PARTY website. The BORN2PARTY website does not
                        control the availability, content, privacy policy, quality, and completeness
                        of the services of other websites and pages to which it refers through
                        links, hyperlinks, or advertising banners. Therefore, for any problem
                        encountered during their visit/use or for any questions regarding them, you
                        should address them directly to the respective websites and pages, which are
                        responsible for providing their services, and not to the administrator of
                        this website. The BORN2PARTY website should not be considered to endorse or
                        accept the content or services of the websites and pages it refers to or to
                        be associated with them in any other way.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.5 Intellectual and Industrial Property Rights"
                    secondary={
                      <p>
                        The BORN2PARTY website may contain hyperlinks or advertising banners linking
                        to other websites for information provided by third parties. Referral to
                        other websites is for the convenience of the public and in no way creates
                        any commitment by the BORN2PARTY website. The BORN2PARTY website does not
                        control the availability, content, privacy policy, quality, and completeness
                        of the services of other websites and pages to which it refers through
                        links, hyperlinks, or advertising banners. Therefore, for any problem
                        encountered during their visit/use or for any questions regarding them, you
                        should address them directly to the respective websites and pages, which are
                        responsible for providing their services, and not to the administrator of
                        this website. The BORN2PARTY website should not be considered to endorse or
                        accept the content or services of the websites and pages it refers to or to
                        be associated with them in any other way.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.6. User Obligations & Liability"
                    secondary={
                      <>
                        <p>
                          <b>3.6.1.</b> Users agree and acknowledge that they will make lawful and
                          appropriate use of the BORN2PARTY website and the provided services, and
                          that they will adhere to the Codes of Ethics and Policies posted on it
                          and/or provided for in the relevant applicable legislation.
                        </p>
                        <p>
                          <b>3.6.2.</b> Users are obliged to refrain from any illegal, contrary to
                          transactional ethics, unfair, and abusive use of the content of the
                          website and the services and not to engage in acts or omissions that may
                          cause damage or malfunction to the website or to third parties or affect
                          or endanger the provision of the website&apos;s Services.
                        </p>
                        <p>
                          <b>3.6.3.</b> Indicatively and not restrictively, users of the BORN2PARTY
                          website agree that they will not use the website for:
                        </p>
                        <p>
                          1. Sending, publishing, posting, emailing, or transmitting in any way any
                          content that is illegal for any reason, causes illegal offense and damage
                          to the website or any third party, or violates the confidentiality or
                          privacy of any person&apos;s information.
                        </p>
                        <p>
                          2. Sending, publishing, posting, emailing, or transmitting in any way any
                          content that offends user morals, social values, minors, etc.
                        </p>
                        <p>
                          3. Sending, publishing, posting, emailing, or transmitting in any way any
                          content that infringes any patent, trademark, trade secret, or violates
                          intellectual property rights or other proprietary rights of third parties.
                        </p>
                        <p>
                          4. Sending, publishing, posting, emailing, or transmitting in any way any
                          material containing software viruses or any other codes, files, or
                          programs designed to interrupt, cause damage, destroy, or equip computer
                          software or hardware.
                        </p>
                        <p>
                          5. Intentional or unintentional violation of applicable Greek and
                          Community legislation or its provisions.
                        </p>
                        <p>6. Harassment of third parties in any way and any content,</p>
                        <p>
                          7. Collecting, storing, processing, and/or disclosing the personal data of
                          other users and/or third parties
                        </p>
                        <p>8. Endangering the security of the website network.</p>
                        <p>
                          <b>3.6.4.</b> Users are solely responsible for the use of the BORN2PARTY
                          website, as well as for any damage that may be caused to them or to any
                          third party due to or as a result of the above use, and must fully
                          indemnify the website against any claim that may be made by any third
                          party as a result of the use of the website and/or the services offered.
                        </p>
                        <b>3.6.5.</b>
                        <p>
                          Users/&quot;Event Organizers&quot; are solely responsible for the accuracy
                          of the details of the Event, indicatively and not restrictively for the
                          accuracy of the venue, the time of the event, the venue, the capacity,
                          etc.
                        </p>
                        <p>
                          <b>3.6.6.</b> Users/&quot;Event Organizers&quot; are solely responsible
                          for the safety of the &quot;Attendees to the Event,&quot; as well as for
                          any damage that may be caused to them or to any third party due to or as a
                          result of the Event, furthermore the &quot;Attendees to the Event&quot;
                          are responsible for any damage that may be caused to the &quot;Event
                          Organizer&quot; or to any third party due to or as a result of the Event.
                        </p>
                        <p>
                          <b>3.6.7.</b> Users/&quot;Event Organizers&quot; and &quot;Attendees to
                          the Event&quot; are solely responsible for the smooth conduct of the
                          event, respecting the relevant provisions of the current legislation.
                        </p>
                        <p>
                          <b>3.6.8.</b> In any case of illegal or contrary to these terms and the
                          current legal framework use of the website by a User, the latter is
                          obliged to indemnify the BORN2PARTY website for any positive and/or
                          negative damage suffered as a result of the above actions.
                        </p>
                        <p>
                          <b>3.6.9.</b> The User accepts that they will not represent or act on
                          behalf of any other person or any other company or entity and will not use
                          false or unauthorized information.
                        </p>
                        <p>
                          <b>3.6.10.</b> The User accepts that they will not gain access to the
                          website&apos;s database without the prior written permission of the latter
                          and will not steal data or personal information in any way.
                        </p>
                        <p>
                          <b>3.6.11.</b> The User agrees not to violate or attempt to violate the
                          security mechanisms of the website – including accessing any data or
                          server to which they are not authorized to access – or the overall
                          security of this website and its associated services.
                        </p>
                        <p>
                          <b>3.6.12.</b> The User agrees not to copy, reproduce, republish, modify,
                          distribute, display publicly, decompile, disassemble, create derivative
                          works, sublicense, transfer, or adapt any of the software, information,
                          text, graphics, source code, or any other content from the website without
                          written permission.
                        </p>
                        <p>
                          <b>3.6.13.</b> The User agrees not to use the website and its services in
                          a manner that contradicts the present Terms and Conditions for purposes
                          other than those for which the website was created and operates, and for
                          which the services are provided. Specifically, but not limited to, the
                          User is obliged not to use the website and its services for any illegal
                          activity or to transmit or post defamatory, offensive, abusive,
                          threatening, or obscene content or content that may cause annoyance,
                          disturbance, or undue concern.
                        </p>
                        <p>
                          <b>3.6.14.</b> The User agrees not to use any personal contact details of
                          other users, acquired from the website, for any purpose other than
                          communication regarding the Event they have posted on the website.
                          Specifically, and not limited to, they guarantee not to use this
                          information for sending unauthorized commercial or non-commercial
                          messages, and not to disclose or share it with third parties unless
                          expressly permitted by the present Terms of Use or with the prior consent
                          of a specific User after adequate disclosure of the purpose for which such
                          communication is intended. In any case, the User agrees to use any
                          information acquired through the website in accordance with applicable
                          Greek and European legislation and regulations, including but not limited
                          to Greek legislation on the protection of personal data and the
                          preservation of privacy.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="3.7 Reporting Issues"
                    secondary={
                      <p>
                        Users of our services are provided with the opportunity to voluntarily
                        report to us on the message board any incident that has come to their
                        attention and pertains to one of the following categories by sending the
                        relevant report through the functionality included in their account or
                        profile, respectively: – Malfunction = Any problem encountered by the User
                        in the operation of the website(s). – Inappropriate Content = Content
                        contained, including but not limited to, in a photograph, text, or link to a
                        third-party website, judged contrary to what is stated in paragraphs 5.1.1.,
                        5.2.1., and 5.3. – False Information = Information posted through the
                        functions/services of the website(s) and judged contrary to what is stated
                        in paragraph 5.2.1. – Unfair Advertising = Any form of advertising products
                        or services or posting advertising links to products or services judged
                        contrary to what is stated in paragraph 5.1.2. – Malicious Behavior =
                        Behavior (such as, for example, posting text or using the functions of the
                        website(s) in any way) involving particularly a threat of violence or other
                        illegal act or omission, insult, offense to sexual dignity, or extortion. –
                        Annoying Behavior = Behavior that, while not malicious, causes concern to
                        the recipient, through, for example, posting text or using the functions of
                        the website(s) in any way. The report will be examined by the website within
                        a reasonable time.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.8. Payments"
                    secondary={
                      <p>The website provides its services to registered Users for free.</p>
                    }
                  />
                  <CustomListItemText
                    primary="3.9. Termination of BORN2PARTY Website Usage"
                    secondary={
                      <p>
                        By the present, the User of the BORN2PARTY website accepts that the website
                        reserves the right to terminate access to it or any part thereof at any time
                        if it becomes aware of any event which, in its judgment, is contrary to the
                        law and the present terms without the obligation of prior notice.
                        Additionally, the User accepts that the website may temporarily and/or
                        permanently suspend its operation for any reason and at any time without the
                        obligation of prior notice, and in case of any damage or harm caused by the
                        aforementioned reasons, the website bears no responsibility. It is
                        recognized that any provision of these terms that imposes or provides for
                        continuing obligations on a contracting party shall remain in force even
                        after the expiration or termination of these terms. Similarly, the validity
                        of all licenses granted to the BORN2PARTY website will be maintained even
                        after the exclusion of the User from using it.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.10. User Codes"
                    secondary={
                      <p>
                        Every User who wishes to register can create their own account using the
                        login details from their Google account, and the User is solely responsible
                        for not disclosing their password as well as for all actions taken with the
                        Password. In case of loss, theft, or unauthorized use of the password, the
                        User must inform the BORN2PARTY website immediately. Any email and
                        communication sent by the User to the BORN2PARTY website, as well as any
                        action taken on their personal account or Profile, is deemed to be carried
                        out by the User themselves using their personal password unless they have
                        duly informed the BORN2PARTY website otherwise. The website is not
                        responsible for any damage or loss resulting from the arbitrary or illegal
                        use of the Password by third parties.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.11. Acceptance of Terms of Use"
                    secondary={
                      <>
                        <p>
                          1. By using the BORN2PARTY website, it is deemed that the visitor/User
                          agrees to all the terms stated on this website, and reading this text is
                          required before using our services. Furthermore, your consenting opinion
                          as well as the complete and unconditional acceptance of the stated terms
                          are expressed, certified, and declared responsibly by clicking the
                          &quot;Acceptance of Terms of Use&quot; button and any other link that may
                          lead to the use of the provided services, and it is considered as your
                          signature on this text.
                        </p>
                        <p>
                          2. The use of the BORN2PARTY website by minors (individuals who have not
                          reached the age of 18) and by individuals lacking legal capacity is
                          prohibited. By registering, the User declares that they are an adult and
                          have legal capacity, and undertakes not to allow the use of their
                          information by minors or legally incapacitated individuals. In case such
                          use of access information is detected, the User alone is responsible.
                        </p>
                        <p>
                          3. Each use of the services provided by the website is possible only if
                          the visitor/User has previously unconditionally accepted the
                          aforementioned terms of agreement, as an additional proof that the
                          visitor/User has obtained full knowledge of the terms and unreservedly
                          agreed to them. The present terms of use of the BORN2PARTY website are
                          formulated based on the entirety of the legal rules of the Greek
                          territory, governed by Greek law, the current legislation of the European
                          Union, and International Treaties, and are interpreted according to the
                          rules of good faith, commercial ethics, and the social and economic
                          purpose of the right.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="3.12. All Terms of Use are essential. "
                    secondary={
                      <p>
                        The Terms of Use constitute a single whole and represent the final and
                        complete agreement of the contracting parties. This agreement is considered
                        fully and mutually accepted and replaces any previous negotiation,
                        discussion, assurance, promise, or agreement, written or oral, that may have
                        been made on the subject of the agreement. The website has the right to
                        unilaterally modify the Terms of Use without prior notice to the
                        visitor/User. The latest updated version of the Terms of Use of the provided
                        services or products will be posted by the website in a special section. The
                        article headings are indicative and without binding significance, included
                        solely for the convenience of reading the text, do not modify the Terms of
                        Use, and do not affect the rights and obligations of the parties arising
                        from the Terms of Use.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.13. Partial Invalidity"
                    secondary={
                      <p>
                        In the event that a term or provision of these terms of use, or a prediction
                        of such terms, is deemed invalid or cancellable, such invalidity or
                        cancellation shall not affect the validity of the remaining terms, and the
                        parties shall make every reasonable effort to replace the invalid or
                        cancellable predictions or terms with others that as closely as possible
                        approximate the content of the invalid or cancellable terms or predictions.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.14. Waiver"
                    secondary={
                      <p>
                        Any waiver of these Terms of Use or of the rights of any of the contracting
                        parties or of the compensations arising shall be made in writing to be
                        effective. The failure, neglect, or delay of any of the contracting parties
                        to enforce the provisions of this agreement or their rights or compensations
                        at any time shall not be construed or deemed as a waiver by such contracting
                        party of its rights under this Agreement in any manner whatsoever and shall
                        not affect the validity of the Ter
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.15. Assignment"
                    secondary={
                      <p>
                        The website has the right to assign or transfer all of its rights and
                        obligations under these Terms of Use without the prior written consent of
                        the User, especially to universal or special successors thereof. The User
                        has no right to assign or transfer in whole or in part its rights and
                        obligations arising from these Terms of Use without the prior written
                        consent of the website.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.16. Force Majeure"
                    secondary={
                      <p>
                        The BORN2PARTY website shall not be liable for any problems in the use of
                        access via the internet of the visitor/user, due to force majeure. Reasons
                        of force majeure include, but are not limited to, earthquakes, fires,
                        floods, war, strikes, short circuits, and problems in telecommunications
                        connections. Neither of the contracting parties shall be liable for any
                        delay or failure to perform an obligation arising from these Terms of Use
                        due to reasons of force majeure. For as long as force majeure and its
                        consequences last, the validity of these Terms of Use shall be suspended.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.17. Applicable Law and Other Terms"
                    secondary={
                      <p>
                        The above terms and conditions of use of the BORN2PARTY website, as well as
                        any modification, change, or alteration thereof, are governed and
                        supplemented by Greek law, European Union law, and relevant international
                        treaties. For any dispute arising between the Parties in connection with the
                        execution of these terms, the courts of Athens shall have jurisdiction of
                        all degrees and jurisdictions. Any provision of the above terms that becomes
                        contrary to the law shall automatically cease to apply and be removed from
                        this, without in any case affecting the validity of the other terms. This
                        constitutes the entire agreement between the BORN2PARTY website and the
                        visitor/user of its pages and services and binds only them. No modification
                        of these terms shall be considered or become part of this agreement unless
                        it has been formulated in writing and incorporated herein. In accordance
                        with Directive 2013/11/EC, which was incorporated into Greek law by
                        Ministerial Decision 70330/2015 and Decision 31619 Government Gazette
                        969/22.3.2017 (Article 8), the possibility of electronic resolution of
                        consumer disputes is provided for throughout the European Union through the
                        Alternative Dispute Resolution process. If a registered User has any dispute
                        with the website and resides in the EU, they may use the website{' '}
                        <CustomLink link="https://webgate.ec.europa.eu/odr/main/?event=main.home.show" />{' '}
                        for the out-of-court settlement of the dispute.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="Limitation of Liability - Disclaimer Statement"
                    secondary={
                      <>
                        <p>
                          The use of the BORN2PARTY website is solely at the User&apos;s risk, and
                          all information contained therein is provided &quot;as is&quot; without
                          any express or implied warranty in any way. The website does not provide
                          any guarantee regarding the achievement of results concerning the
                          identification of &quot;Attendees to the Event&quot;, nor that the Website
                          or its Services will meet the requirements or expectations of the
                          Visitor/User/Event Organizer/Attendee to the Event. We reserve the right
                          to limit or terminate your access to the BORN2PARTY website or any part or
                          function thereof at any time.
                        </p>
                        <p>
                          While the website does not guarantee the absence of errors or
                          typographical errors in the texts contained therein, it will make every
                          reasonable effort to correct them as soon as they come to its attention.
                          The website, while taking necessary protection measures, accepts no
                          liability and does not guarantee in any case that the content, pages, and
                          technical facilitations and capabilities of the website will be provided
                          continuously or without problems, or that the website and/or servers
                          through which it is connected to the visitors&apos; electronic computer
                          will be provided without viruses or other harmful applications. The
                          BORN2PARTY website does not guarantee that the information received or
                          sent by Users through the website is inaccessible to unauthorized persons,
                          or that the information contained is correct, accurate, adequate, useful,
                          timely, reliable, or in any case complete. The storage of any content or
                          part of the BORN2PARTY website on a personal computer or portable device
                          is the responsibility of the Visitor/User, who is solely responsible for
                          any damage caused to their property due to the storage of content from the
                          BORN2PARTY website on their personal computer or portable device. Adopting
                          any advice or receiving any information from the BORN2PARTY website does
                          not create a warranty obligation on our part.
                        </p>
                        <p>
                          The Visitor/User of the pages of the BORN2PARTY website assumes sole
                          responsibility for any damage caused by misuse of the relevant services.
                          In no event shall the BORN2PARTY website be liable for any legal, civil,
                          or criminal claims or for any damages (positive, special, or
                          consequential, which include but are not limited to, loss of profits,
                          data, foregone profits, monetary satisfaction, etc.) incurred by a Visitor
                          or User of the BORN2PARTY website or a third party due to circumstances
                          related to 1) the BORN2PARTY website or any other website or source
                          referring to the BORN2PARTY website via a link, 2) any action we take or
                          fail to take as a result of communication sent to us, 3) the Registered
                          User&apos;s account or Profile on the BORN2PARTY website or any deletion
                          or cancellation thereof, 4) any delay or inability to use the website or
                          the information, product, or service advertised or provided through the
                          website, 5) any modification, removal, or deletion of any content posted
                          or posted on the BORN2PARTY website, 6) any use of the website.
                        </p>
                        <p>
                          In no event shall the BORN2PARTY website be liable for any legal, civil,
                          or criminal claims or for any damages (positive, special, or
                          consequential, which include but are not limited to, loss of profits,
                          data, foregone profits, monetary satisfaction, etc.) incurred by a Visitor
                          or User of the BORN2PARTY website or a third party due to circumstances
                          related to the conduct of the Event.
                        </p>
                        <p>
                          Furthermore, the Event Organizers&apos; are solely responsible for the
                          accuracy of the details of the Event, including but not limited to the
                          accuracy of the venue, time of the Event, space, capacity, etc.
                          Additionally, the Event Organizers&apos; are solely responsible for the
                          safety of the &apos;Attendees to the Event&apos;, any legal, civil, or
                          criminal claims, as well as liability for any damages incurred by them or
                          any third party due to or as a result of the Event. Moreover, the
                          &apos;Attendees to the Event&apos; are responsible for any legal, civil,
                          or criminal claims, as well as liability for any damages incurred by them
                          or any third party due to or as a result of the Event, as well as for any
                          damages caused to the &apos;Event Organizer&apos; or any third party due
                          to or as a result of the Event. Finally, the Event Organizers and the
                          Attendees to the Event bear sole responsibility for the smooth conduct of
                          the event, respecting the relevant provisions of the applicable
                          legislation.
                        </p>
                        <p>
                          The Visitor/User of the BORN2PARTY website bears the responsibility to
                          assess the accuracy, completeness, or usefulness of any opinion, advice,
                          or other content available on the website or retrieved from any other
                          website or source through a link. This statement pertains, but not limited
                          to, any damage or loss caused by any inaccuracy, error, omission,
                          interruption, deletion, defect, delay in operation or transmission,
                          malicious software, digital file corruption, network or system damage, any
                          loss of profits of the User of the BORN2PARTY website, theft, destruction,
                          unauthorized access, alteration, loss, or use of any record or data, and
                          any material or non-material damage.
                        </p>
                        <p>
                          By the present, the Visitor/User of the BORN2PARTY website acknowledges
                          and agrees that neither the website nor the collaborating professionals or
                          third-party content providers are responsible for any defamatory,
                          offensive, or illegal conduct of any Visitor/User of the BORN2PARTY
                          website. Remedying the damage of the injured party for any of the above
                          claims or any dispute with the BORN2PARTY website consists solely of
                          discontinuing the use of the website. Collaborating professionals, whose
                          products and services are available on the BORN2PARTY website, are
                          independent contractors and not agents or employees of the BORN2PARTY
                          website.
                        </p>
                        <p>
                          Specifically, the BORN2PARTY website does not guarantee and disclaims any
                          liability for inaccuracies or errors regarding information on the
                          description of the Events posted on the BORN2PARTY website, including but
                          not limited to photographs, general descriptions of events, etc.
                        </p>
                        <p>
                          For any dispute arising between the Visitor/User of the BORN2PARTY website
                          and any third party, expressly agrees that the BORN2PARTY website has no
                          obligation to side with any party regarding the dispute. For any dispute
                          arising between the Visitor/User of the BORN2PARTY website and any third
                          party, expressly agrees that the BORN2PARTY website, its directors,
                          officers, employees, agents, and successors are released from any claim,
                          demand, and damage of any nature, known or unknown, asserted or not,
                          arising from or in any way related to the above dispute and/or the website
                          and/or the service provided thereby (including but not limited to any
                          service review posted by the registered User of the website or his/her
                          business collaborating professional).
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="Contact"
                    secondary={
                      <p>
                        Communication with the BORN2PARTY website is done electronically by sending
                        an email to <CustomLink email link="info@borntopary.app" />. By this, the
                        User agrees to receive electronically any communication from the BORN2PARTY
                        website related to the use and services thereof. Finally, I undertake the
                        obligation to promptly inform the BORN2PARTY website of any changes to the
                        above personal data. The BORN2PARTY website undertakes to limit its
                        processing only to the absolutely necessary for the purposes of data
                        processing, as declared by me or with my assistance, and to avoid the
                        registration of sensitive data. The BORN2PARTY website undertakes to
                        maintain the confidentiality and privacy of the above data, the processing
                        of which will be carried out exclusively by order of the BORN2PARTY website,
                        by persons who guarantee the confidentiality and meet the requirements to
                        ensure the privacy and who are under the control of the BORN2PARTY website.
                        The BORN2PARTY website undertakes to take any necessary technical or
                        organizational measures for the security of data and their protection from
                        accidental or unlawful destruction, accidental loss, alteration,
                        unauthorized dissemination or access, and any other unlawful processing. I
                        have the right of access to the above data concerning me personally, as well
                        as the right to object, in accordance with the applicable law, respectively.
                        For more information, refer to the privacy policy of the website by clicking
                        here{' '}
                        <Link
                          to="/privacy-policy"
                          component={RouterLink}
                          underline="always"
                          color="inherit"
                        >
                          personal data protection terms
                        </Link>
                        .
                      </p>
                    }
                  />
                </>
              }
            />
          </List>
        </Typography>
      </Container>
    </>
  );
}
