import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import {Box, Stack, Drawer, Typography} from '@mui/material';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// config
import {COMMIT_ID, NAV} from '../../../../config-global';
// components
import Logo from '../../../theme-components/logo';
import Scrollbar from '../../../theme-components/scrollbar';
import { NavSectionVertical } from '../../../theme-components/nav-section';
//
import navConfig, {navByRole} from './config-navigation';
import NavDocs from './NavDocs';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';
import {useAuthContext} from '../../../auth/contexts/useAuthContext'

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();
  const { user } = useAuthContext()
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!user) return <div>Could not found user</div>;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Stack sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          width: '100%',
        }}>
          <Logo/>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            variant='h4'
          >
            { user.impersonated ? 'ADMIN' : 'B2P' }
          </Typography>
        </Stack>

        {/* <NavAccount /> */}
      </Stack>

      <NavSectionVertical data={navByRole(user && user.role)} />

      <Box sx={{ flexGrow: 1 }} />

      <NavDocs />

      { COMMIT_ID && <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '10px',
        color: '#536a88',
        marginBottom: '10px'
      }}> v.{ COMMIT_ID } </Box> }

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
        backgroundColor: user.impersonated ? 'black' : 'inherit'
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
