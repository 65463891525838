// eslint-disable-next-line import/no-extraneous-dependencies
import {IParty, IUser} from 'project-types'
import { ThunkGenerator } from '../ThunkGenerator'
import AuthResource from './AuthResource'
import {GenericThunkGenerator} from '../GenericThunkGenerator'

const AuthThunks = new ThunkGenerator<IParty>()
const AuthThunksGeneric = new GenericThunkGenerator()

const resource = new AuthResource()

export default {
  googleLogin: AuthThunks.generateGet('Auth/login/google', () => resource.googleLogin()),
  impersonate: AuthThunksGeneric.generate<
    { res: IUser },
    { userID: string }
  >('Auth/impersonation', (props) => resource.impersonate(props.userID)),
}
