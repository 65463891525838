import {Stack, Typography} from '@mui/material'
import {RHFDateTimePicker} from '../../theme-components/hook-form'
import {START_DATE} from '../../../redux/slices/partySlicer'


const inputProps = {
  // fullWidth: true,
  sx: { width: '100%' }
}

export default function DateFilter () {
  return <Stack spacing={1}>
    <Typography variant="subtitle1"> Period </Typography>
    <RHFDateTimePicker minDateTime={START_DATE} name="startDate" label="From" inputProps={inputProps} />
    <RHFDateTimePicker name="endDate" label="To" inputProps={inputProps} />
  </Stack>
}
