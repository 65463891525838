import {IPartyNoId} from 'project-types'

import { MAP_FALLBACK_COORDINATES } from '../../../config-global'
import {MapCoordinates} from '../../../@types/party'

export default {
  easeToCoordinates: (mapRef: any, coordinates: MapCoordinates, options: Record<string, unknown> = {}) => {
    if (!coordinates) return 
    mapRef.current?.easeTo(
      {
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 15,
        duration: 2500,
        ...options
      }
    )
  },
  getCurrentLocationOrFallback: async (fallback?: MapCoordinates): Promise<MapCoordinates> => {
    const fallbackCoordinates = fallback || { latitude: MAP_FALLBACK_COORDINATES.lat, longitude: MAP_FALLBACK_COORDINATES.lng }
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude })
          },
          (err) => {
            resolve(fallbackCoordinates)
          }
        )
      } catch (e) {
        console.log(e)
        resolve(fallbackCoordinates)
      }
    })
  },
  extractPartyCoordinates: (partyLocation: IPartyNoId['location']): MapCoordinates => {
    const lat = partyLocation?.lat || (partyLocation as any)?.latitude
    const lng = partyLocation?.long || (partyLocation as any)?.longitude
    if (!lat || !lng) return null
    return {
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
    }
  }
}
