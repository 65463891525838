// @mui
import {InputAdornment, Stack, TextField} from '@mui/material'
// components
import {useState} from 'react'
import {RHFDateTimePicker, RHFTextField} from '../../theme-components/hook-form'
import PartyFilterDrawer from '../partyFilter/PartyFilterDrawer'
import Iconify from '../../theme-components/iconify'

const inputProps = {
  fullWidth: true,
  sx: { maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' } }
}

type Props = {
  search: string;
  // filterRole: string;
  // isFiltered: boolean;
  // onResetFilter: VoidFunction;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // onFilterRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function PartyTableToolbar({
  // isFiltered,
  search,
  onSearch,
  // onFilterRole,
  // onResetFilter,
}: Props) {
  const [openFilter, setOpenFilter] = useState(false);
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction='row'
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        value={search}
        onChange={onSearch}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {/* <RHFDateTimePicker name="startDate" inputProps={inputProps} /> */}
      {/* <RHFDateTimePicker name="endDate" inputProps={inputProps} /> */}
      {/*  Maybe remove this from RHF to prevent on change triggers */}
      {/* <RHFTextField name="search" label="" /> */}
      {/* <PartyFilterDrawer */}
      {/*   open={openFilter} */}
      {/*   onOpen={() => setOpenFilter(true)} */}
      {/*   onClose={() => setOpenFilter(false)} */}
      {/* /> */}
    </Stack>
  );
}
