// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs'
import {DATE_FORMAT} from '../../../config-global'

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  label?: string;
  disablePast?: boolean;
  minDateTime?: Date;
  inputProps?: any
};

export default function RHFDateTimePicker({
  name,
  helperText,
  inputProps = {},
  label = 'Date',
  disablePast = true,
  minDateTime = disablePast ? new Date() : undefined,
  ...other
}: Props) {
  const { control } = useFormContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
          <DateTimePicker
            {...field}
            inputFormat={DATE_FORMAT}
            disablePast={disablePast}
            minDateTime={minDateTime}
            label={label}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                name={name}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...params}
                {...inputProps}
                {...other}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
