import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#FDE6DC',
  light: '#F5A095',
  main: '#DF4D55',
  dark: '#A02643',
  darker: '#6B0E34',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#C5F6E1',
  light: '#52CDAF',
  main: '#005A54',
  dark: '#003940',
  darker: '#001E2B',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#C9FBED',
  light: '#5DE8D8',
  main: '#01ACB5',
  dark: '#006682',
  darker: '#003556',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FAD0',
  light: '#72E281',
  main: '#1CA048',
  dark: '#0E7343',
  darker: '#054C39',
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FEF7CB',
  light: '#F9DE65',
  main: '#EDB602',
  dark: '#AA7A01',
  darker: '#714C00',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FEE7D4',
  light: '#F9A27E',
  main: '#ED442A',
  dark: '#AA1518',
  darker: '#71081B',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: { black: '#000000', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#FFFFFF', default: '#FFFFFF', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}
