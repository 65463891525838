import {IPartyComment} from 'project-types'
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import CommentThunks from './PartyCommentThunks'
import {PartyCommentsState} from '../../types'

export default (builder: ActionReducerMapBuilder<PartyCommentsState>): void => {
  /**
   * GET MANY
   */
  builder
    .addCase(CommentThunks.getMany.pending, (state) => {
      state.loading = true
    })
    .addCase(CommentThunks.getMany.fulfilled, (state, { payload }) => {
      state.comments = payload.res
      state.loading = false
    })
    .addCase(CommentThunks.getMany.rejected, (state, payload) => {
      state.loading = false
    })

  /**
   * SAVE
   */
  builder
    .addCase(CommentThunks.save.pending, (state) => {
      state.loading = true
    })
    .addCase(CommentThunks.save.fulfilled, (state, { payload }) => {
    })
    .addCase(CommentThunks.save.rejected, (state, payload) => {
      state.loading = false
    })

  /**
   * REPLY
   */
  builder
    .addCase(CommentThunks.reply.pending, (state) => {
      state.loading = true
    })
    .addCase(CommentThunks.reply.fulfilled, (state, { payload }) => {
    })
    .addCase(CommentThunks.reply.rejected, (state, payload) => {
      state.loading = false
    })

  /**
   * DELETE
   */
  builder
    .addCase(CommentThunks.delete.pending, (state) => {
      state.loading = true
    })
    .addCase(CommentThunks.delete.fulfilled, (state, { payload }) => {
      state.comments = state.comments.filter((partyComment: IPartyComment) => partyComment.id !== payload.id)
      state.loading = false
    })
    .addCase(CommentThunks.delete.rejected, (state, payload) => {
      state.loading = false
    })
}
