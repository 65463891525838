// eslint-disable-next-line import/no-extraneous-dependencies
import {IProfile} from 'project-types'
import {ThunkGenerator} from '../ThunkGenerator'
import ProfileResource from './ProfileResource'

const ProfileThunks = new ThunkGenerator<IProfile>()
const resource = new ProfileResource()
export default {
  get: ProfileThunks.generateGet('Profiles/get', (resourceID) => resource.get(resourceID)),
  getMany: ProfileThunks.generateGetMany('Profiles/getMany', (filters) => resource.getMany(filters)),
}
