import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {LocationState} from '../types'
import GeoThunkReducers from '../api/geo/GeoThunkReducers'

export const initialState: LocationState = {
  loading: false,
  autocompleteQuery: null,
  results: [],
  details: null,
  session: null
}

export const locationSlicer = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLoading: (state: LocationState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    regenerateSession: (state: LocationState) => {
      state.session = Date.now().toString()
      state.results = []
      state.details = null
    },
  },
  extraReducers: GeoThunkReducers
})

export const { setLoading, regenerateSession } = locationSlicer.actions
export default locationSlicer.reducer
