import {IAttendee} from 'project-types'
import ResourceBase from '../ResourceBase'
import {PagedResource} from '../ThunkGenerator'

type ResourceType = IAttendee
type ResourceResponse = {
    res: ResourceType[]
    count: number
}

export default class AttendeeResource extends ResourceBase<ResourceType, ResourceResponse> {
  private endpoint = 'attendees'

  async getMany (props: { partyId: string, filters: Record<string, any>}): Promise<PagedResource<ResourceType>> {
    const query = {}
    const response = await this.request<PagedResource<ResourceType>>({
      method: 'GET',
      urlPath: `/parties/${props.partyId}/${this.endpoint}/`,
      queryParameters: query
    })
    return response
  }

  async getAttendance (props: { partyId: string }): Promise<{ res: { attendance: number, capacity: number } }> {
    const query = {}
    const response = await this.request<{ res: { attendance: number, capacity: number } }>({
      method: 'GET',
      urlPath: `/parties/${props.partyId}/${this.endpoint}/count`,
      queryParameters: query
    })
    return response
  }

  async patch (props: { partyId: string, resource: Partial<ResourceType>[] }): Promise<ResourceType> {
    const response = await this.request({
      method: 'PATCH',
      urlPath: `/parties/${props.partyId}/${this.endpoint}/`,
      body: ({
        attendees: props.resource
      }) as any
    })
    return response.res[0]
  }

  async delete (props: { partyId: string, resourceIDs: string[] }): Promise<ResourceType[]> {
    const response = await this.request({
      method: 'DELETE',
      urlPath: `/parties/${props.partyId}/${this.endpoint}`,
      body: ({
        attendeeIds: props.resourceIDs
      }) as any
    })
    return response.res
  }

  async isUserAttending (props: { partyId: string, userId?: string }): Promise<PagedResource<ResourceType>> {
    const query: any = {}
    if (props.userId) { query.attendeeId = props.userId }
    const response = await this.request<PagedResource<ResourceType>>({
      method: 'GET',
      urlPath: `/parties/${props.partyId}/${this.endpoint}/`,
      queryParameters: query
    })
    return response
  }
}

