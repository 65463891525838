import { Button } from "@mui/material";
import Iconify from "../theme-components/iconify/Iconify";
import {useDispatch} from '../../redux/store'
import AuthThunks from '../../redux/api/auth/AuthThunks'

export default function AuthWithGoogle(props: { text?: string }) {
  const { text = 'Sign in with Google' } = props
  const dispatch = useDispatch()
  return (
    <div>
      <Button
        fullWidth
        variant="outlined" 
        color="inherit"
        size="large"
        sx={{
          border: '2px solid',
          borderColor: 'text.primary',
          color: (theme) => (theme.palette.mode === 'light' ? 'grey.800' : 'common.white'),
          '&:hover': {
            background: 'white',
            color: (theme) => (theme.palette.mode === 'light' ? 'grey.800' : 'common.white'),
          },
        }}
        startIcon={<Iconify icon="logos:google-icon" color="#DF3E30" />}
        onClick={() => {
          dispatch(AuthThunks.googleLogin('0'))
        }}
      >
        { text }
      </Button>
    </div>
  )
}
