import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../../theme-components/loading-screen';
//
import Login from '../../../pages/auth/LoginPage';
import { useAuthContext } from '../contexts/useAuthContext';
import useLocalStorage from '../../../hooks/useLocalStorage'
import isRunningInWebView, {PromptToOpenInBrowser} from '../../../utils/webviewDetection'

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

const isWebView = isRunningInWebView();

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();
  const [settings, setSettings] = useLocalStorage('navigation', { path: null });
  const { pathname } = useLocation();

  if (!isInitialized) return <LoadingScreen />

  if (!isAuthenticated) {
    if (pathname !== settings.path) {
      setSettings({ path:pathname });
    }
    return isWebView ? <PromptToOpenInBrowser/> : <Login />;
  }

  if (settings?.path && settings.path !== pathname && user && user.role === 'user') {
    // console.log('AuthGuard: Redirecting to', settings.path, 'from', pathname, 'user:', user);
    return <Navigate to={settings.path} />;
  }

  if (settings?.path && settings?.path === pathname) {
    setSettings({ path: null })
  }

  return <> {children} </>;
}
