import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {LocationAutocomplete} from 'project-types'
import Thunks from './GeoThunks'
import {LocationState} from '../../types'

export default (builder: ActionReducerMapBuilder<LocationState>): void => {
  /**
     * AUTOCOMPLETE
     */
  builder
    .addCase(Thunks.autocomplete.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.autocomplete.fulfilled, (state, { payload }) => {
      const emptyLocation: LocationAutocomplete = {
        description: '',
        placeId: ''
      }
      state.results = [...payload.res, emptyLocation]
      state.loading = false
    })
    .addCase(Thunks.autocomplete.rejected, (state, payload) => {
      state.loading = false
    })

  /**
     * GET DETAILS
     */
  builder
    .addCase(Thunks.getDetails.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.getDetails.fulfilled, (state, { payload }) => {
      state.details = payload.res
      state.loading = false
    })
    .addCase(Thunks.getDetails.rejected, (state, payload) => {
      state.loading = false
    })
}
