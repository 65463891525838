// Define a base service using createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IImpression } from 'project-types';
import CONFIG from '../../config';

type ImpressionsResponse = {
  res: {
    count: number;
    partyId: string;
    percentageChange: number;
  };
};

type AggregationResponse = {
  res: {
    buckets: {
      count: number;
      date: string;
    }[];
  };
};

interface StatsQueryArgs {
  partyId?: string;
  fromDate?: string;
  toDate?: string;
}

enum Tags {
  impr = 'impr',
  uv = 'uv',
  att = 'att'
}

export const statsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api,
    credentials: 'include'
  }),
  refetchOnMountOrArgChange: 10,
  tagTypes: [Tags.impr, Tags.uv, Tags.att],
  endpoints: (builder) => ({
    getImpressions: builder.query<ImpressionsResponse, StatsQueryArgs>({
      query: (args) => {
        if (!args.partyId) {
          return {
            url: '',
            params: {}
          };
        }
        return {
          url: `/parties/${args.partyId}/stats/impressions/count`,
          params: {
            startDate: args.fromDate,
            endDate: args.toDate
          }
        };
      },
      providesTags: [Tags.impr],
    }),
    getImpressionsHistogram: builder.query<AggregationResponse, StatsQueryArgs>({
      query: (args) => ({
        url: `/parties/${args.partyId}/stats/impressions/histogram`,
        params: {
          startDate: args.fromDate,
          endDate: args.toDate
        }
      }),
      providesTags: [Tags.impr],
    }),
    getUniqueVisitors: builder.query<ImpressionsResponse, StatsQueryArgs>({
      query: (args) => {
        if (!args.partyId) {
          return {
            url: '',
            params: {}
          };
        }
        return {
          url: `/parties/${args.partyId}/stats/impressions/unique/count`,
          params: {
            startDate: args.fromDate,
            endDate: args.toDate
          }
        };
      },
      providesTags: [Tags.uv],
    }),
    getUniqueVisitorsHistogram: builder.query<AggregationResponse, StatsQueryArgs>({
      query: (args) => ({
        url: `/parties/${args.partyId}/stats/impressions/unique/histogram`,
        params: {
          startDate: args.fromDate,
          endDate: args.toDate
        }
      }),
      providesTags: [Tags.uv],
    }),
    getAttendees: builder.query<ImpressionsResponse, StatsQueryArgs>({
      query: (args) => {
        if (!args.partyId) {
          return {
            url: '',
            params: {}
          };
        }
        return {
          url: `/parties/${args.partyId}/stats/attendees/count`,
          params: {
            startDate: args.fromDate,
            endDate: args.toDate
          }
        };
      },
      providesTags: [Tags.att],
    }),
    getAttendeesHistogram: builder.query<AggregationResponse, StatsQueryArgs>({
      query: (args) => ({
        url: `/parties/${args.partyId}/stats/attendees/histogram`,
        params: {
          startDate: args.fromDate,
          endDate: args.toDate
        }
      }),
      providesTags: [Tags.att],
    }),
    addImpression: builder.mutation<IImpression, { partyId: string; impression: Partial<IImpression> }>({
      query: ({ partyId, impression }) => ({
        url: `/parties/${partyId}/stats/impressions/`,
        method: 'PATCH',
        body: impression
      }),
      invalidatesTags: [Tags.impr]
    }),
  }),
});

// Auto-generated hooks for the operations
export const {
  useGetImpressionsQuery,
  useGetImpressionsHistogramQuery,
  useAddImpressionMutation,
  useGetUniqueVisitorsQuery,
  useGetUniqueVisitorsHistogramQuery,
  useGetAttendeesQuery,
  useGetAttendeesHistogramQuery
} = statsApi;
