import { useState } from 'react';
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material';
// utils
import { IParty } from 'project-types'
import dayjs from 'dayjs'
import { fDate } from '../../../utils/formatTime'
import {fCurrency, fCurrencySymbol, fNumber } from '../../../utils/formatNumber'
// @types
// components
import Label from "../../theme-components/label"
import Iconify from "../../theme-components/iconify"
import MenuPopover from "../../theme-components/menu-popover"
import ConfirmDialog from "../../theme-components/confirm-dialog"
import { CustomAvatar } from "../../theme-components/custom-avatar"
import {generateLinearGradient} from '../../../utils/gradient'
import {useSelector} from '../../../redux/store'

// ----------------------------------------------------------------------

type Props = {
    row: IParty;
    selected: boolean;
    onEditRow: (id: string) => void;
    onSelectRow: (id: string) => void;
    onDeleteRow: (id: string) => void;
};

export default function PartyTableRow(props: Props) {
  const gradients = useSelector(state => state.gradients.list)
  const { row, selected, onEditRow, onSelectRow, onDeleteRow } = props
  const { name, status, location, budget, date, id, music, createdAt, userID, attendance, preferences, style } = row
  const { capacity, drinks } = preferences
  const gradient = gradients.find(g => g.id === style?.gradientId)

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

  const handleOpenConfirm = () => setOpenConfirm(true)
  const handleCloseConfirm = () => setOpenConfirm(false)

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => setOpenPopover(event.currentTarget)
  const handleClosePopover = () => setOpenPopover(null)

  const formatDrinks = (drinksBoolean: boolean) => !drinksBoolean
    ? <Iconify sx={{ color: 'success.main' }} icon="ph:check-circle-fill" />
    : ''

  const passedDate = dayjs(date).isBefore(dayjs(), 'day')

  return (
    <TableRow data-cy="party-table-row" hover selected={selected} key={id}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={() => onSelectRow(id)} />
      </TableCell>

      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CustomAvatar
            sx={{ background: `${generateLinearGradient(gradient ? gradient.colors : [])}`, cursor: 'pointer' }}
            onClick={() => onEditRow(id)}
          />

          <div>
            <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} noWrap onClick={() => onEditRow(id)}>
              {name}
            </Typography>

            <Link
              noWrap
              variant="body2"
              sx={{ color: 'text.disabled', cursor: 'pointer' }}
            >
              {location.address}
            </Link>
          </div>
        </Stack>
      </TableCell>

      <TableCell align="left">
        <Stack direction='row'>
          { passedDate && <Tooltip title="This is a past party"><Iconify sx={{ color: 'warning.main', marginRight: '10px'}} icon="wi:time-8" /></Tooltip> }
          { fDate(date) }
        </Stack>
      </TableCell>

      <TableCell align="left">
        <Label
          variant="soft"
          color={
            (status === 'public' && 'success') ||
            'default'
          }
        >
          {status}
        </Label>
      </TableCell>

      <TableCell align="center">{fNumber(attendance || 0)}</TableCell>
      <TableCell align="center">{capacity || '-'}</TableCell>
      <TableCell align="center">{
        drinks == null ? '-' : formatDrinks(drinks)
      }</TableCell>

      {/* <TableCell align="center">{fCurrency(budget.amount, budget.currency)}</TableCell> */}
      <TableCell align="center">
        <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow(id);
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow(id)
            handleCloseConfirm()
          }}>
            Delete
          </Button>
        }
      />
    </TableRow>
  );
}
