import CONFIG from '../config'

export type requestPropsType<ResourceType> = {
    method: 'POST' | 'PUT' | 'PATCH' | 'GET' | 'DELETE'
    urlPath: string,
    queryParameters?: Record<string, string>
    headers?: { [key: string]: string },
    body?: Partial<ResourceType>
    credentials?: RequestCredentials | undefined
}

class ResourceBase<ResourceType, ResourceResponseType> {
  // eslint-disable-next-line class-methods-use-this
  async request<RequestResponse = ResourceResponseType > (props: requestPropsType<ResourceType>): Promise<RequestResponse> {
    const { urlPath, method, headers = {}, body, credentials = 'include' } = props
    const queryParametersString = this.objectToQueryParamString(props.queryParameters)

    let url = `${CONFIG.api}${urlPath}`
    if (queryParametersString !== undefined) {
      url += `?${  queryParametersString}`
    }

    headers['Content-Type'] = 'application/json'
    const apiResponse = await fetch(
      url,
      {
        method,
        headers,
        body: JSON.stringify(body),
        credentials
      }
    )
    if (apiResponse.status !== 200) throw new Error(apiResponse.statusText)
    return await apiResponse.json() as RequestResponse
  }

  // eslint-disable-next-line class-methods-use-this
  objectToQueryParamString (filters: Record<string, string | null | string[]> | undefined): string | undefined {
    if (filters == null) return undefined
    return Object.entries(filters)
      .filter(([key, value]) => value != null)
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
      })
      .join('&')
  }
}

export default ResourceBase
