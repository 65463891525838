// @mui
import { useTheme } from '@mui/material/styles';
import { IconButton, IconButtonProps } from '@mui/material';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// utils
import { bgBlur } from '../../../../utils/cssStyles';
// config
import { NAV } from '../../../../config-global';
// components
import Iconify from '../../../theme-components/iconify';
import {dispatch, RootState, useSelector} from '../../../../redux/store';
import {setNavOpen} from '../../../../redux/slices/appSlicer'

// ----------------------------------------------------------------------

export default function NavToggleButton({ sx, ...other }: IconButtonProps) {
  const theme = useTheme()
  const { navOpen } = useSelector((state) => state.app)
  const isDesktop = useResponsive('up', 'lg');

  if (!isDesktop) return null;

  return (
    <IconButton
      size="small"
      onClick={() => { dispatch(setNavOpen(!navOpen)) }}
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_DASHBOARD - 12,
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.divider}`,
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={navOpen ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'}
      />
    </IconButton>
  );
}
