// @mui
import { alpha, useTheme } from '@mui/material/styles';
import {Tooltip, Box, Badge} from '@mui/material';
// utils
//
import {bgBlur} from '../../../utils/cssStyles'
//
import SvgColor from '../../theme-components/svg-color'
import {IconButtonAnimate} from '../../theme-components/animate'

// ----------------------------------------------------------------------

type Props = {
    onToggle: VoidFunction;
};

export default function ToggleFiltersButton({ onToggle }: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: '50%',
        boxShadow: `-12px 12px 32px -4px ${alpha(
          theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
          0.36
        )}`,
        ...bgBlur({ color: '#FFFFFF' }),
      }}
    >
      <Tooltip title="Filters">
        <IconButtonAnimate color="primary" onClick={onToggle} sx={{ p: 0.5 }}>
          <SvgColor src="/assets/icons/setting/ic_setting.svg" />
        </IconButtonAnimate>
      </Tooltip>
    </Box>
  );
}
