import { Navigate, useRoutes } from 'react-router-dom'
// auth
import AuthGuard from '../components/auth/guards/AuthGuard'
import GuestGuard from '../components/auth/guards/GuestGuard'
import CompactLayout from '../components/layouts/compact'
import DashboardLayout from '../components/layouts/dashboard'
// config
import { PATH_AFTER_LOGIN } from '../config-global'
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  UserAccountPage,
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  PartyNewPostPage,
  UserListPage,
  UserCardsPage,
  UserProfilePage,
  DiscoverPartiesPage,
  PartyPage
} from './elements';
import UserRoleGuard from '../components/auth/guards/UserRoleGuard'
import AdminRoleGuard from '../components/auth/guards/AdminRoleGuard'
import PageDisabledUser from '../pages/misc/PageDisabledUser'
import PartyListPage from '../pages/admin/PartyListPage'
import MainLayout from '../components/layouts/main'
import HomePage from '../pages/home/HomePage'
import AboutPage from '../pages/home/AboutPage'
import FaqsPage from '../pages/home/FaqsPage'
import TermsOfServices from '../pages/terms-of-services/TermsOfServices'
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy'
import {useSnackbar} from '../components/theme-components/snackbar'

// ----------------------------------------------------------------------

export default function Router() {
  const { enqueueSnackbar } = useSnackbar()
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');
  if (error === 'login') {
    enqueueSnackbar('Login failed 😢. Try again in a while or contact us', { variant: 'error' });
  }
  return useRoutes([
    // Root route
    { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    // Parties
    {
      path: 'parties',
      element: (
        <AuthGuard>
          <UserRoleGuard>
            <DashboardLayout />
          </UserRoleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/parties/find' replace />, index: true },
        { path: 'find', element: <DiscoverPartiesPage /> },
        { path: 'new', element: <PartyNewPostPage /> },
        { path: 'list', element: <PartyListPage /> },
        { path: ':id', element: <PartyPage /> },
      ],
    },
    // Users
    {
      path: 'users',
      element: (
        <AuthGuard>
          <UserRoleGuard>
            <DashboardLayout />
          </UserRoleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/users/explore' replace />, index: true },
        { path: 'explore', element: <UserCardsPage /> },
        { path: ':id', element: <UserProfilePage /> },
      ],
    },
    // Settings
    {
      path: 'settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <UserAccountPage />, index: true }
      ],
    },
    // admin THIS SHOULD BE UNDER ADMIN GUARD
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AdminRoleGuard>
            <DashboardLayout />
          </AdminRoleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/admin/user-list' replace />, index: true },
        { path: 'user-list', element: <UserListPage /> },
        { path: 'party-list', element: <PartyListPage /> }
      ],
    },
    // other routes
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'user-disabled', element: <PageDisabledUser /> }
      ],
    },
    // {
    //   path: '/home',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about', element: <AboutPage /> },
    //     // { path: 'contact-us', element: <Contact /> },
    //     // { path: 'pricing', element: <PricingPage /> },
    //     { path: 'faqs', element: <FaqsPage /> },
    //   ],
    // },
    {
      path: '/terms-of-services',
      element: <CompactLayout fullWidth />,
      children: [
        { element: <TermsOfServices />, index: true },
      ]
    },
    {
      path: '/privacy-policy',
      element: <CompactLayout fullWidth />,
      children: [{ element: <PrivacyPolicy />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
