import {IPartyComment} from 'project-types'
import ResourceBase from '../ResourceBase'
import {PagedResource} from '../ThunkGenerator'

type ResourceType = IPartyComment
type ResourceResponse = {
    res: ResourceType[]
    count: number
}

export default class PartyCommentResource extends ResourceBase<ResourceType, ResourceResponse> {
  private endpoint = '/partyComments'

  async get (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'GET',
      urlPath: `${this.endpoint}/${resourceID}`
    })
    return response.res[0]
  }

  async getMany (filters: Record<string, any>): Promise<PagedResource<ResourceType>> {
    const query = {
      'order': filters.order,
      'orderDirection': filters.orderDirection,
      'partyId': filters.partyId
    }
    const response = await this.request<PagedResource<ResourceType>>({
      method: 'GET',
      urlPath: `${this.endpoint}/`,
      queryParameters: query
    })
    return response
  }

  async post (resource: ResourceType): Promise<ResourceType> {
    const { ...body } = resource
    const response = await this.request<{ res: IPartyComment }>({
      method: 'POST',
      urlPath: `${this.endpoint}`,
      body
    })
    return response.res
  }

  async reply (resourceID: string, resource: Partial<ResourceType>): Promise<ResourceType> {
    const { ...body } = resource
    const response = await this.request({
      method: 'POST',
      urlPath: `${this.endpoint}/${resourceID}/reply`,
      body
    })
    return response.res[0]
  }

  async delete (resourceID: string): Promise<ResourceType> {
    const response = await this.request({
      method: 'DELETE',
      urlPath: `${this.endpoint}/${resourceID}`
    })
    return response.res[0]
  }
}

