import {IParty } from 'project-types'
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import Thunks from './PartyThunks'
import {PartiesState} from '../../types'

export default (builder: ActionReducerMapBuilder<PartiesState>): void => {
  /**
     * GET
     */
  builder
    .addCase(Thunks.get.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.get.fulfilled, (state, { payload }) => {
      state.party = payload
      state.loading = false
    })
    .addCase(Thunks.get.rejected, (state, payload) => {
      state.loading = false
    })

  /**
     * GET MANY
     */
  builder
    .addCase(Thunks.getMany.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.getMany.fulfilled, (state, { payload }) => {
      state.parties = payload.res
      state.count = payload.count
      // if (payload.pagination) state.pagination = payload.pagination
      // if (payload.order) state.order = payload.order
      state.loading = false
    })
    .addCase(Thunks.getMany.rejected, (state, payload) => {
      state.loading = false
    })

  /**
   * EXPLORE
   */
  builder
    .addCase(Thunks.explore.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.explore.fulfilled, (state, { payload }) => {
      state.parties = payload.res
      state.count = payload.count
      // if (payload.pagination) state.pagination = payload.pagination
      // if (payload.order) state.order = payload.order
      state.loading = false
    })
    .addCase(Thunks.explore.rejected, (state, payload) => {
      state.loading = false
    })

  /**
     * SAVE
     */
  builder
    .addCase(Thunks.save.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.save.fulfilled, (state, { payload }) => {
      // state.parties.push(payload)
      state.party = payload
    })
    .addCase(Thunks.save.rejected, (state, payload) => {
      state.loading = false
    })

  /**
     * UPDATE
     */
  builder
    .addCase(Thunks.update.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.update.fulfilled, (state, { payload }) => {
      state.parties = state.parties.map((party: IParty) => {
        if (party.id === payload.id) party = payload
        return party
      })
      state.loading = false
    })
    .addCase(Thunks.update.rejected, (state, payload) => {
      state.loading = false
    })

  /**
     * DELETE
     */
  builder
    .addCase(Thunks.delete.pending, (state) => {
      state.loading = true
    })
    .addCase(Thunks.delete.fulfilled, (state, { payload }) => {
      state.parties = state.parties.filter((party: IParty) => party.id !== payload.id)
      state.loading = false
    })
    .addCase(Thunks.delete.rejected, (state, payload) => {
      state.loading = false
    })

  /**
   * AGGREGATIONS
   */
  builder
    .addCase(Thunks.aggregations.pending, (state) => {
      state.aggregations.loading = true
    })
    .addCase(Thunks.aggregations.fulfilled, (state, { payload }) => {
      state.aggregations.status = payload.res.status
      state.aggregations.total = payload.res.total
      state.aggregations.loading = false
    })
    .addCase(Thunks.aggregations.rejected, (state, payload) => {
      state.aggregations.loading = false
    })

}
