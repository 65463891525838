import {useState} from 'react';
// @mui
import {
  Box,
  Badge,
  Tooltip,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
// utils
import {INotification} from 'project-types'
import {useNavigate} from 'react-router-dom'
import Iconify from '../../../theme-components/iconify';
import MenuPopover from '../../../theme-components/menu-popover';
import { IconButtonAnimate } from '../../../theme-components/animate';
import NotificationList from '../../../notifications/NotificationList'
import {
  useGetReadNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useMarkAsReadMutation
} from '../../../../redux/api/notifications/NotificationQuery'

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const polling = { pollingInterval: 15000, skipPollingIfUnfocused: true }
  const query = {}

  const unreadNotificationsResults = useGetUnreadNotificationsQuery(query, polling);
  const readNotificationResults = useGetReadNotificationsQuery(query);
  const [markAsRead] = useMarkAsReadMutation();

  const unreadNotifications = unreadNotificationsResults.data?.res || []
  const readNotifications = readNotificationResults.data?.res || []
  const totalUnRead = unreadNotifications?.length || 0

  const navigate = useNavigate();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
    unreadNotificationsResults.refetch()
    readNotificationResults.refetch()
  };
  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const handleNotificationClick = (notification: INotification) => {
    markAsRead(notification.id)
    handleClosePopover()
    switch (notification.type) {
    case 'partyComment':
      navigate(`/parties/${notification.partyId}?commentId=${notification.commentId}`)
      break;
    case 'partyCommentReply':
      navigate(`/parties/${notification.partyId}?commentId=${notification.commentId}`)
      break;
    case 'partyAttendance':
      navigate(`/parties/${notification.partyId}?attendeeId=${notification.attendeeId}`)
      break;
    default:
      break;
    }
  }
  const handleMarkAllAsRead = () => {
    unreadNotifications.forEach((notification) => {
      markAsRead(notification.id)
    })
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
        data-testid="notifications"
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0, overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {totalUnRead === 0
                ? 'You have read all notifications'
                : `You have ${totalUnRead} unread notifications`}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <NotificationList
          oldNotifications={readNotifications}
          newNotifications={unreadNotifications}
          handleNotificationClick={handleNotificationClick}
        />

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}> */}
        {/*   <Button fullWidth disableRipple> */}
        {/*     View All */}
        {/*   </Button> */}
        {/* </Box> */}
      </MenuPopover>
    </>
  );
}
