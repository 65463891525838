import { Navigate } from 'react-router-dom';
import { PATH_PAGE, PATH_PARTIES } from '../../../routes/paths';
import { useAuthContext } from '../contexts/useAuthContext';

// ----------------------------------------------------------------------

type AdminRoleGuardProps = {
  children: React.ReactNode;
};

export default function AdminRoleGuard({ children }: AdminRoleGuardProps) {
  const { isAuthenticated, user } = useAuthContext();
  if (isAuthenticated && user && user.role !== 'admin') {
    if (user.role === 'user') return <Navigate to={PATH_PARTIES.root} />
    return <Navigate to={PATH_PAGE.page403} />;
  }
  return <> {children} </>;
}
