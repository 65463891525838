import {Button, Link, Stack, TextField, Typography} from '@mui/material'
import Logo from '../components/theme-components/logo'
import LoginLayout from '../components/layouts/login'
import {useSnackbar} from '../components/theme-components/snackbar'

function isRunningInWebView() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(userAgent)
}

const PromptToOpenInBrowser = () => {
  const { enqueueSnackbar } = useSnackbar()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
      // enqueueSnackbar('Login failed 😢. Try again in a while or contact us', { variant: 'error' });
        enqueueSnackbar('URL copied to clipboard 📋', { variant: 'success' });
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
        enqueueSnackbar('Failed to copy URL to clipboard 😢', { variant: 'error' });
      });
  };
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{mb: 15, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Logo
          className='balloon-logo'
          sx={{
            zIndex: 9,
            width: '100px',
            height: '100px'
          }}
        />
        <Typography variant="body2" sx={{textAlign: 'center', position: 'absolute', top: 20}}>
          For the best experience, open in an external browser. Tap the three dots in the corner and select Open in browser.
        </Typography>
      </Stack>
      <Stack>
        <TextField
          value={window.location.href}
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          sx={{ mt: 2 }}  // Adjust spacing as necessary
        />
        <Button variant="contained" onClick={copyToClipboard} sx={{ mt: 1 }}>
          Copy URL
        </Button>
      </Stack>
    </LoginLayout>
  )
}

export default isRunningInWebView
export { PromptToOpenInBrowser }
