import {Avatar, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography} from '@mui/material'
import {INotification} from 'project-types'
import {noCase} from 'change-case'
import Iconify from '../theme-components/iconify'
import {fToNow} from '../../utils/formatTime'

type NotificationItemProps = {
  notification: INotification;
  onClick: (notification: INotification) => void;
}
export default function NotificationItem({ notification, onClick }: NotificationItemProps) {
  const { avatar, title } = renderContent(notification);
  return (
    <ListItemButton
      onClick={() => onClick && onClick(notification)}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.readAt === null && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.createdAt)}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

function renderContent(notification: INotification) {
  const NotificationTitle = 'New Notification'
  const NotificationDesc = 'New Notification Description'
  const avatar = null
  const Title = (title: string, description: string) => (
    <Typography variant="subtitle2">
      {title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'partyComment') {
    const commenterName = notification.commenter?.name
    const commenterImage = notification.commenter?.profileImage
    return {
      avatar: <img alt={notification.message} src={commenterImage} />,
      title: Title(`${commenterName}`, `commented on your party`),
    };
  }
  if (notification.type === 'partyCommentReply') {
    const commenterName = notification.commenter?.name
    const commenterImage = notification.commenter?.profileImage
    return {
      avatar: <img alt={notification.message} src={commenterImage} />,
      title: Title(`${commenterName}`, `replied to your comment`),
    };
  }
  if (notification.type === 'partyAttendance') {
    const commenterName = notification.attendee?.name
    const commenterImage = notification.attendee?.profileImage
    return {
      avatar: <img alt="" src={commenterImage} />,
      title: Title(`${commenterName}`, `is attending your party`),
    };
  }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
  //     title,
  //   };
  // }
  return {
    avatar: avatar ? <img alt={NotificationTitle} src={avatar} /> : null,
    title: Title(NotificationTitle, NotificationDesc)
  };
}
