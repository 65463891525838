import { Helmet } from 'react-helmet-async';
// @mui
import { Container, List, Typography } from '@mui/material';
import {
  CustomLink,
  CustomListItem,
  CustomListItemText,
} from '../terms-of-services/TermsOfServices';

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy | Born To Party</title>
      </Helmet>
      <Container sx={{ pt: 15, pb: 10, position: 'relative' }}>
        <Typography variant="h3" sx={{ mb: 5, color: 'primary.main' }}>
          Privacy Policy
        </Typography>
        <Typography variant="h5" sx={{ mb: 1, textAlign: 'left' }}>
          Statement of Personal Data Protection on the BORN2PARTY website
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'left' }}>
          The protection of personal data and information is of utmost priority for the BORN2PARTY
          website and the basis for the trust relationship we seek to establish with visitors to the
          website and users of our services.
          <List sx={{ listStyle: 'decimal', pl: 4, mt: 5 }}>
            <CustomListItem
              title="Definitions"
              content={
                <CustomListItemText
                  secondary={
                    <>
                      <p>
                        - A &quot;<b>Visitor</b>&quot; is defined as a person who visits the
                        borntoparty.app website and/or the application without being a
                        &quot;User&quot; thereof.
                      </p>
                      <p>
                        - A &quot;<b>Registered User/User</b>&quot; is defined as a person who has
                        registered on the website and/or the BORN2PARTY application by entering the
                        required information and has access to all the functionalities of the
                        website and/or the BORN2PARTY application.
                      </p>
                      <p>
                        - An &quot;<b>Event Organizer</b>&quot; is an individual, sole
                        proprietorship, or legal entity that has created a personal
                        &quot;Profile&quot; on the website and/or the BORN2PARTY application and
                        represents themselves and/or their subcontractors regarding participation on
                        the website and/or the application.
                      </p>
                      <p>
                        - An &quot;<b>Event Seeker</b>&quot; is a &quot;Registered User&quot;
                        seeking an event.
                      </p>
                      <p>
                        - A &quot;<b>Personalized Page</b>&quot; is the section of the website
                        and/or the application created exclusively by Registered Users for sharing
                        an event.
                      </p>
                      <p>
                        - An &quot;<b>Event Creator</b>&quot; is the creator of the event.
                      </p>
                      <p>
                        - An &quot;<b>Attendee of the Event</b>&quot; is a person included in the
                        Attendee List of the Event and who has expressed their intention to
                        participate in the event.
                      </p>
                      <p>
                        - An &quot;<b>Attendee List of the Event</b>&quot; is the section of the
                        website and/or the application created exclusively by the Registered User
                        and includes the personal information of third parties who will attend an
                        Event.
                      </p>
                      <p>
                        - An &quot;<b>Account Profile</b>&quot; is the section of the website and/or
                        the application accessible exclusively by the Registered User.
                      </p>
                      <p>
                        - A &quot;<b>Search</b>&quot; is the section of the website and/or the
                        application where all active Events are displayed.
                      </p>
                      <p>
                        - An &quot;<b>Event Addition</b>&quot; is defined as the section of the
                        website and/or the application where the Registered User can submit an
                        upcoming Event. &quot;
                      </p>
                      <p>
                        - <b>&quot;Filters</b>&quot; refer to the section of the website and/or the
                        application where the registered User configures the search criteria for
                        Events.
                      </p>
                      <p>
                        - An &quot;<b>Event Management</b>&quot; is the section of the website
                        and/or the application where the Registered User has access to the Events
                        they have shown interest in.
                      </p>
                      <p>
                        - A &quot;<b>Donation</b>&quot; is the section of the website and/or the
                        application where the Registered User has the option to make a donation to
                        the website and/or the application.
                      </p>
                      <p>
                        - A &quot;<b>Feedback</b>&quot; is the section of the website and/or the
                        application where the registered User can provide feedback on the website
                        and/or the application.
                      </p>
                      <p>
                        - A &quot;<b>Link</b>&quot; is an electronic address of a website located
                        outside the borntoparty.app website.
                      </p>
                      <p>
                        - &quot;<b>Website</b>&quot; or &quot;<b>Site</b>&quot; or &quot;
                        <b>BORN2PARTY website</b>&quot; or &quot;<b>BORN2PARTY site</b>&quot; refers
                        to the website: borntoparty.app
                      </p>
                      <p>
                        - &quot;<b>Application</b>&quot; refers to the BORN2PARTY application.
                      </p>
                    </>
                  }
                />
              }
            />
            <CustomListItem
              title="Data Controller and Processor"
              content={
                <>
                  <CustomListItemText
                    secondary={
                      <>
                        <p>
                          The website and/or the BORN2PARTY application hereinafter referred to as
                          &quot;BORN2PARTY&quot; or &quot;we&quot; or &quot;data processor&quot; or
                          &quot;owner&quot; or &quot;data controller&quot; or &quot;responsible for
                          personal data processing&quot;, is the administrator and responsible for
                          the use of user data collected during their visit to the website{' '}
                          <CustomLink link="https://borntoparty.app" /> in accordance with Law
                          4624/2019 as amended by Law 5043/2023, EU Directive on Data Protection
                          (95/46/EC), and Regulation (EU) 2016/679.
                        </p>
                        <p>
                          The BORN2PARTY website is interested in privacy issues and wants you to
                          know how we collect, use, and disclose information.
                        </p>
                        <p>
                          This Privacy Policy describes our practices regarding the information we
                          collect through the website operated and controlled by us and to which you
                          have access to this Privacy Policy. By visiting our website, providing
                          personal information to us, or using it in any way, such as by registering
                          for our services, you unreservedly accept the practices described in this
                          Privacy Policy, which is an integral part and whole with our Terms of Use,
                          with which it should be read and interpreted. Owner&apos;s email address:
                          info@borntoparty.app.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="2.1. What is the processing of personal data?"
                    secondary={
                      <p>
                        It is any act or series of acts carried out with or without the use of
                        automated means, on personal data or sets of personal data, such as
                        collection, recording, organization, structuring, storage, adaptation or
                        alteration, retrieval, consultation, use, disclosure by transmission,
                        dissemination or otherwise making available, alignment or combination,
                        restriction, erasure, or destruction.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="2.2. Personal data we process"
                    secondary={
                      <p>
                        Personal data is any information relating to an identified or identifiable
                        natural person (&quot;data subject&quot;); an identifiable natural person is
                        one who can be identified, directly or indirectly, in particular by
                        reference to an identifier such as a name, an identification number,
                        location data, or one or more factors specific to the physical,
                        physiological, genetic, mental, economic, cultural, or social identity of
                        that natural person.
                      </p>
                    }
                  />
                </>
              }
            />
            <CustomListItem
              title="Collection of Personal Data from the BORN2PARTY Website"
              content={
                <>
                  <CustomListItemText
                    primary="3.1. Registration – Account Creation"
                    secondary={
                      <p>
                        To utilize the services of the BORN2PARTY website, you will be prompted to
                        register and create an account through which you can access the services.
                        During the registration process, you will be required to create an account
                        using the login credentials from your Google social media account.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.2. Event Creation"
                    secondary={
                      <p>
                        On the event page, registered users have the option to utilize the message
                        boards, where they can post text related to the upcoming event and/or share
                        links to websites.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.3. Posting Text / Using Message Boards"
                    secondary={
                      <p>
                        The BORN2PARTY website and/or application may record the content of text
                        posted by users on the website solely for the purpose of detecting
                        violations of their terms of use, as specified in the website&apos;s terms
                        of use, and for no other purpose. The BORN2PARTY website is not authorized
                        to disclose the content of such messages to third parties. Users who utilize
                        the chat board service agree that the name they registered with on the
                        BORN2PARTY website and the messages they post on the chat board are
                        accessible to all users of that specific chat board. Requests from users for
                        the deletion of messages and information posted on chat boards will only be
                        accepted in special cases and if there is an extremely serious reason, which
                        must be adequately justified by the respective user. However, this remains
                        at the discretion of the management team of the{' '}
                        <CustomLink link="https://borntoparty.app" />
                        website. You can send your request to the email address
                        <CustomLink email link="info@borntoparty.app" />, clearly stating the
                        information you wish to delete.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.4. Cookies"
                    secondary={
                      <p>
                        The BORN2PARTY website may use cookies to recognize visitors/users on
                        certain services and pages of the BORN2PARTY website. For more information,
                        please refer to section 14 of this privacy statement.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary='3.5. Links to Third-Party Websites ("Links")'
                    secondary={
                      <p>
                        The BORN2PARTY website includes links (&quot;links&quot;) to other websites
                        that are not controlled by the same but by third parties (natural or legal
                        persons). Under no circumstances is the BORN2PARTY website responsible for
                        the Privacy Policies followed by these third parties.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.6. Third-Party Service Providers"
                    secondary={
                      <>
                        <p>Service providers may vary over time, but may include the following:</p>
                        <p>
                          - Data analytics service providers for analyzing the browsing behavior of
                          visitors and users on the BORN2PARTY website to better serve them.
                        </p>
                        <p>- Google Analytics (Google Inc.)</p>
                        <p>
                          Google Analytics is a web analytics service provided by Google Inc
                          (&quot;Google&quot;). Google uses the data collected to track and examine
                          the use of this application, to prepare reports on its activities and
                          share them with other Google services. Google may use the data collected
                          to contextualize and personalize the ads of its own advertising network.
                          Personal Data collected: Cookies and Usage Data. For more information on
                          Google&apos;s privacy policy, click here:{' '}
                          <CustomLink link="https://policies.google.com/privacy?hl=en" />
                        </p>
                        <p>
                          - Service providers that help us determine your location based on your
                          address
                        </p>
                        <p>
                          - Widely available data sources, such as free government databases or
                          other freely available data.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="3.7. We Do Not Collect:"
                    secondary={
                      <p>
                        - Sensitive information: we request that you do not send us or disclose
                        sensitive personal data (such as social security numbers, information about
                        race or ethnic origin, political opinions, religion or other beliefs,
                        health, criminal background, or your participation in trade unions) through
                        the services of the website or by any other means.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="3.8. Payments"
                    secondary={
                      <p>The website provides its services to registered Users for free.</p>
                    }
                  />
                  <CustomListItemText
                    primary="3.8. Connected Services "
                    secondary={
                      <p>
                        Lastly, the services of the BORN2PARTY website may also be linked to
                        websites, including social networking sites, managed by non-affiliated
                        companies, which may provide advertisements or offer content, functionality,
                        newsletters, contests, or applications developed and maintained by
                        non-affiliated companies. The BORN2PARTY website is not responsible for the
                        privacy practices of non-affiliated companies, and once you leave the
                        services of the BORN2PARTY website or click on an advertisement, you should
                        check the applicable Privacy Policy of the other service.
                      </p>
                    }
                  />
                </>
              }
            />
            <CustomListItem
              title="Use of Personal Data by the website BORN2PARTY and Legal Basis for Processing"
              content={
                <>
                  <CustomListItemText
                    secondary={
                      <p>
                        We process the following categories of personal data for the purposes
                        outlined below and in accordance with the specific legal bases:
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="4.1. Account Creation / Logging into Your Account / Account Information/Profile on Google"
                    secondary={
                      <>
                        <p>
                          On our website, you can register via the &quot;Google Sign-In&quot;
                          service provided by Google Ireland Limited, Gordon House, 4 Barrow St,
                          Dublin, D04 E5W5, Ireland (&quot;Google&quot;), using the so-called single
                          sign-on technology, provided you have a Google profile. You can recognize
                          the Google login function on our website by the &quot;Sign in with
                          Google&quot; button.
                        </p>
                        <p>
                          When you call up a page on our website that contains a Google login
                          function, your browser establishes a direct connection to Google&apos;s
                          servers. The content of the login button is transmitted directly from
                          Google to your browser and integrated into the page. Through this
                          integration, Google receives information that your browser has called up
                          the corresponding page of our website, even if you do not have a Google
                          profile or are not currently logged in to Google. This information
                          (including your IP address) is transmitted directly from your browser to a
                          Google server and stored there - this may also involve the transfer to
                          servers of Google LLC in the USA. This data processing is carried out in
                          accordance with Article 1 of the Constitution. 6 para. 1 based on
                          Google&apos;s legitimate interest in displaying personalized advertising
                          based on browsing behavior.
                        </p>
                        <p>
                          By using the Google login button on our website, you also have the option
                          to log in or register on our website using your Google user data. Only if
                          you give your explicit consent before the registration process based on a
                          corresponding notification regarding the data exchange with Google, we
                          receive, when using the Google button, from Google, depending on your
                          privacy settings at Google, the general and publicly accessible
                          information stored in their profile. This information includes the user
                          ID, name, profile picture, age, and gender.
                        </p>
                        <p>
                          We would like to point out that, following changes to Google&apos;s
                          privacy policy and terms of use, there may also be a transfer of your
                          profile images, the user IDs of your friends, and the list of friends if
                          they have been marked as &quot;public&quot; in your privacy settings on
                          Google. The data transmitted by Google will be stored and processed by us
                          to create a user account with the necessary data (title, first name, last
                          name, address, country, email address, date of birth) if you have released
                          this data to us via Google for this purpose. Conversely, data (e.g.,
                          information about your browsing or purchasing behavior) may be transferred
                          by us to your profile on Google with your consent.
                        </p>
                        <p>
                          The consent given can be revoked at any time by sending a message to the
                          email info@borntoparty.app.
                        </p>
                        <p>
                          For the purpose and scope of data collection and the further processing
                          and use of data by Google, as well as your rights in this regard and
                          setting options for protecting your privacy, please refer to Google&apos;s
                          privacy policy: <CustomLink link="https://policies.google.com/privacy" />{' '}
                          You can view the terms of use for using &quot;Google Sign-In&quot; here:
                          <CustomLink link="https://policies.google.com/terms" />
                        </p>
                        <p>
                          If you do not want Google to directly associate the data collected through
                          our website with your profile on Google, you must log out of Google before
                          visiting our website. You can also completely prevent the loading of
                          Google plugins with add-ons for your browser, e.g., with &quot;Adblock
                          Plus&quot;.(
                          <CustomLink link="https://adblockplus.org/de/" />)
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="4.2. Cookies"
                    secondary={
                      <p>
                        In order to make your visit to our website/application attractive and to
                        enable the use of certain functions, we use so-called cookies on various
                        pages. These are small text files that are stored on your browser. Some of
                        the cookies we use are deleted at the end of the browser session, i.e.,
                        after you close your browser (so-called session cookies). Other cookies
                        remain on your device and allow us to recognize your browser on your next
                        visit (persistent cookies). You can configure your browser to inform you
                        about cookie settings, or through the information panel (cookies banner), or
                        finally from the cookie policy on our website, and to decide individually
                        about their acceptance or rejection for specific cases or in general. The
                        rejection of cookies may limit the functionality of our website. For more
                        information, please refer to section 14.
                      </p>
                    }
                  />
                </>
              }
            />
            <CustomListItem
              title="Processing of Personal Data by the BORN2PARTY Website"
              content={
                <CustomListItemText
                  secondary={
                    <p>
                      Your personal data is processed both electronically and non-automatically and
                      is protected by appropriate security measures, taking into account the state
                      of the art, the cost of implementation, the nature, scope, context, and
                      purposes of processing as well as the different probabilities and the
                      seriousness of the risk to the rights and freedoms of individuals.
                      Specifically, the BORN2PARTY website uses appropriate administrative,
                      technical, personnel-related measures, and physical measures aimed at
                      protecting personal data held by it from loss, theft, and unauthorized use,
                      disclosure, or alteration.
                    </p>
                  }
                />
              }
            />
            <CustomListItem
              title="Who Can Access Your Personal Data"
              content={
                <CustomListItemText
                  secondary={
                    <>
                      <p>
                        The BORN2PARTY website may disclose the personal data of its website
                        visitor/user for the purposes of Section 4 above to the following categories
                        of recipients located within the European Union or outside the European
                        Union in accordance and within the limits of the provisions of Section 8
                        below:
                      </p>
                      <p>
                        a. To third-party service providers who are entrusted with processing
                        activities and, where required by applicable laws, are duly appointed as
                        processors (e.g., cloud service providers, other group entities, service
                        providers serving or supporting the BORN2PARTY website service, and
                        therefore, by way of example and not limitation, IT service providers,
                        consultants, and legal firms-companies arising from possible mergers,
                        splits, or other transformations).
                      </p>
                      <p>
                        b. To affiliated companies in their capacity as data controllers or data
                        processors.
                      </p>
                      <p>
                        c. To competent authorities for compliance purposes with applicable laws.
                      </p>
                      <p>
                        d. To data processors appointed by the company, including companies
                        providing customer support or IT and system protection services and ensuring
                        the quality of our services.
                      </p>
                    </>
                  }
                />
              }
            />
            <CustomListItem
              title="Transfer of Your Personal Data Abroad"
              content={
                <CustomListItemText
                  secondary={
                    <p>
                      The Data of para. 6 may be transferred to countries within and outside the
                      European Economic Area and specifically to the United States. The European
                      Commission recognizes that certain countries outside the EEA provide an
                      adequate level of data protection in accordance with EEA standards. The full
                      list of these countries is available at{' '}
                      <CustomLink link="https://ec.europa.eu/justice/data-protection/internationaltransfers/adequacy/index_en.htm" />
                      . For transfers from the EEA to countries not considered safe by the European
                      Commission, we have implemented appropriate and suitable safeguards aimed at
                      protecting your personal data and transferring your personal data in
                      accordance with the applicable data protection laws, such as standardized
                      contractual clauses approved by the European Commission in accordance with
                      Articles 45 and 46 of Regulation (EU) 2016/679 on data protection (the
                      &quot;General Data Protection Regulation&quot;).
                    </p>
                  }
                />
              }
            />
            <CustomListItem
              title="What Are Your Rights Regarding Your Personal Data"
              content={
                <>
                  <CustomListItemText
                    primary="8.1. Retention Periods Applicable to Your Personal Data"
                    secondary={
                      <p>
                        We will retain your data only for the period necessary to fulfill the
                        purposes for which the data were collected as described in this Privacy
                        Policy. In any case, the following retention periods will apply regarding
                        the processing of your personal data for the purposes described below: Data
                        collected for the purposes described in section 4 of this Privacy Policy
                        will be retained during the provision of the BORN2PARTY website service,
                        plus the period of limitation according to the then-current legislation,
                        after the termination of the Company&apos;s service. Personal data collected
                        for the purposes of section 4 of this Privacy Policy will be retained for
                        the period required for you to log in to the website service via the Google
                        social network. Personal data may be stored for longer periods if the
                        personal data will only be processed for archival purposes in the public
                        interest, for scientific or historical research purposes, or for statistical
                        purposes, pursuant to Article 89 (1) of the General Data Protection
                        Regulation, provided that appropriate technical and organizational measures
                        are implemented to safeguard the rights and freedoms of the data subject
                        (&quot;storage limitation&quot;).
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="8.2. You Have the Right to Request the Website to Restrict the Processing of Your Personal Data in Cases Where:"
                    secondary={
                      <>
                        <p>
                          - You contest the accuracy of the personal data until we take the
                          necessary measures to correct or verify its accuracy,
                        </p>
                        <p>
                          - The processing is unlawful, but you do not want us to delete your
                          personal data,
                        </p>
                        <p>
                          - We no longer need your personal data for the purposes of processing, but
                          you need them for the establishment, exercise, or defense of legal claims,
                          or
                        </p>
                        <p>
                          - You have objected to processing for reasons of legitimate interests
                          pending verification as to whether the website has compelling legitimate
                          grounds to continue processing.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText primary="8.3. You have the right to receive confirmation as to whether your personal data exists and to be informed about its content and origin, to verify its accuracy, and to request its correction, updating, or modification." />
                  <CustomListItemText primary="8.4. You have the right to object to the processing of your personal data, in all cases, for legitimate reasons." />
                  <CustomListItemText primary="8.5. You have the right to request the anonymization or deletion of your personal data without undue delay." />
                  <CustomListItemText primary='8.6. You have the right to receive an electronic copy of your personal data if you wish to transfer your personal data provided to us, either to yourself or to another provider ("data portability") when personal data is processed by automatic means and processing is either (i) based on your consent or (ii) necessary for the performance of the Company&apos;s Service.' />
                  <CustomListItemText primary="8.7. You have the right to withdraw your consent at any time." />
                  <CustomListItemText
                    primary="8.8. Ways to Exercise Your Rights"
                    secondary={
                      <>
                        <p>
                          You can send your request to the email address{' '}
                          <CustomLink email link="info@borntoparty.app" />. In your request, include
                          your email address, name, address, and telephone number, and clearly
                          specify the information you want to access, change, update, delete, or
                          erase.
                        </p>
                        <p>
                          We remind you that even after canceling your account or requesting us to
                          delete your personal data, copies of certain information from your account
                          may remain visible in certain cases where, for example, you have shared
                          information on social media or other services, or when the retention of
                          such copies is necessary for compliance with legal obligations or for
                          legal defense purposes. We may also retain backup copies of information
                          about your account on our servers for a certain period after cancellation
                          or your request for deletion, for compliance purposes with applicable law.
                        </p>
                        <p>
                          In all the above cases, we may contact you and request further information
                          necessary for the proper processing of your request.
                        </p>
                        <p>
                          In any case, you have the right to lodge a complaint with the competent
                          data protection supervisory authority: our intention is to satisfy your
                          legitimate rights promptly, if any of your requests are not met within one
                          month, we inform you whether we will need an additional extension of one
                          more month or we inform you of the reasons we are unable to satisfy your
                          request, in which case you may wish to lodge a complaint with the
                          competent supervisory authority:
                        </p>
                        <p>Supervisory Authority</p>
                        <p>Hellenic Personal Data Protection Authority</p>
                        <p>Offices: Kifisias 1-3, 115 23, Athens</p>
                        <p>Call Center: +30-210 6475600</p>
                        <p>Fax: +30-210 6475628</p>
                        <p>
                          Email: <CustomLink email link="contact@dpa.gr" />
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    secondary={
                      <p>
                        <b>8.9.</b> Users providing their personal data in the context of using the
                        website&aposs services, as well as with this Privacy Policy, will be
                        informed by the website that they consent to and accept the imminent
                        processing of their personal data for the purposes of using the
                        website&aposs services, as well as the transmission of this data to
                        recipients who will be specifically identified and are employees and/or
                        delegates of the website within the scope of providing the website&aposs
                        services.
                      </p>
                    }
                  />
                </>
              }
            />
            <CustomListItem
              title="Respect for Third Party Personal Data"
              content={
                <>
                  <CustomListItemText
                    primary="9.1. Personal Data of Third Parties Registered in the Event Guest List"
                    secondary={
                      <p>
                        The BORN2PARTY website requires registered users to respect the Personal
                        Data of third-party individuals listed on the Event Guest List, in order to
                        process them for the purpose of sending them information about an upcoming
                        event.
                      </p>
                    }
                  />
                  <CustomListItemText
                    primary="9.2. Personal Data of Collaborating Professionals"
                    secondary={
                      <p>
                        Just as the BORN2PARTY website requires collaborating professionals to
                        respect the Personal Data of users, it also requires users to respect the
                        Personal Data of professionals whom they access through the website when a
                        professional submits an offer for them to contact. For example, users are
                        obligated to use the Personal Data of professionals exclusively within the
                        framework and in accordance with the purpose of the website&apos;s
                        operation. Users acknowledge and accept that they will not use the Personal
                        Data of professionals for any purpose that is illegal or contrary to the
                        Terms of Use and other operational policies of the BORN2PARTY website.
                        Furthermore, users acknowledge and accept that they will not disclose the
                        Personal Data of the professional to third parties without their consent.
                        The BORN2PARTY website provides users with the opportunity to voluntarily
                        notify us of any incidents that come to their attention regarding the
                        violation of personal data, by submitting a relevant report through the
                        functionality included in their account or profile respectively.
                      </p>
                    }
                  />
                </>
              }
            />
            <CustomListItem
              title="Mechanisms for Protecting Personal Data by the BORN2PARTY Website"
              content={
                <CustomListItemText
                  secondary={
                    <>
                      <p>
                        We employ reasonable security measures to protect ourselves from the loss,
                        misuse, and alteration of the usage data under our control, during the
                        transmission of data, and after we collect it. This includes, but is not
                        limited to, using firewalls and encryption and access control rights grading
                        systems for users of our systems. All online traffic (file transfer) between
                        this BORN2PARTY website and your browser is encrypted and transmitted using
                        the HTTPS protocol using SSL (Secure Sockets Layer). While we make efforts,
                        in good faith, to maintain the security of such information, no method of
                        transmission over the Internet or method of electronic storage is 100%
                        secure, and we cannot guarantee that data will remain secure from
                        unauthorized access, use, disclosure, or alteration. Additionally, as we
                        work hard to ensure the integrity and security of our network and systems,
                        we cannot guarantee that security measures will prevent hackers or other
                        unauthorized individuals from illegally accessing or obtaining this
                        information.
                      </p>
                      <p>
                        If we become aware of security breaches related to your personal data or
                        usage data, we may attempt to notify you electronically so that you can take
                        necessary steps to protect yourself. By using the BORN2PARTY website or
                        providing personal data to us, you consent to us communicating with you
                        electronically about security, privacy, and management matters related to
                        your activity on the BORN2PARTY website.
                      </p>
                    </>
                  }
                />
              }
            />
            <CustomListItem
              title="Special Obligations of the BORN2PARTY Website"
              content={
                <CustomListItemText
                  secondary={
                    <p>
                      In the event of a breach of communication privacy, which may have resulted in
                      the disclosure of personal data, we notify the competent supervisory authority
                      and the individuals whose personal data has been disclosed, within the
                      required time from the identification of the incident.
                    </p>
                  }
                />
              }
            />
            <CustomListItem
              title="Updates to this Privacy Policy"
              content={
                <CustomListItemText
                  secondary={
                    <>
                      <p>
                        The BORN2PARTY website may modify or update this Privacy Policy for any
                        reason (including, but not limited to, changes in applicable law and
                        interpretations, decisions, opinions, and orders related to such applicable
                        law).
                      </p>
                      <p>
                        Refer to the Effective Date at the top of this Privacy Policy to see when it
                        was last revised. Any changes to this Privacy Policy will be communicated in
                        advance by posting the revised Privacy Policy on the BORN2PARTY
                        website&apos;s Services. In the event that we make material changes to this
                        Privacy Policy that change the nature of processing or expand our rights
                        regarding the use of personal data we have already collected from you, we
                        will notify you and provide you with an option regarding the future use of
                        such personal data, as may be required by applicable law.
                      </p>
                    </>
                  }
                />
              }
            />
            <CustomListItem
              title="Storage location of personal data and communication"
              content={
                <CustomListItemText
                  secondary={
                    <>
                      <p>
                        The data is located in our offices. For more information, please contact us
                        at:
                      </p>
                      <p>
                        E-mail: <CustomLink email link="info@borntoparty.app" />
                      </p>
                    </>
                  }
                />
              }
            />
            <CustomListItem
              title="Cookies Policy"
              content={
                <>
                  <CustomListItemText
                    secondary={
                      <>
                        <p>Last updated: January 24, 2024</p>
                        <p>
                          The BORNTOPARTY website uses cookies on the website http//:borntoparty.app
                          (the &quot;Website&quot;). By using our website, you consent to the use of
                          cookies. Our specific policy on the use of cookies explains what cookies
                          are, how we use cookies, how third parties with whom we may cooperate can
                          use cookies on the website, your options regarding cookies, and additional
                          information about them.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="What are cookies"
                    secondary={
                      <>
                        <p>
                          Cookies are small text files that are sent to your computer&apos;s browser
                          by a website you visit. A cookie file is stored in your computer&apos;s
                          browser and allows the website or a third party to recognize you, making
                          your next visit easier and the website more useful to you.
                        </p>
                        <p>
                          Cookies can be &quot;persistent&quot; or &quot;session&quot; cookies.
                          Persistent cookies remain stored on your personal computer or mobile
                          device even when you&apos;re offline (outside of the browsing program),
                          while session cookies are deleted as soon as you close your browser.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="How the BORNTOPARTY website uses cookies"
                    secondary={
                      <>
                        <p>
                          Every time you use and access the website, we have the ability to send a
                          number of cookie files to your browser. We use cookies for the following
                          purposes:
                        </p>
                        <p>To enable certain functions of the website</p>
                        <p>To provide statistical analyses</p>
                        <p>More specifically:</p>
                        <p>
                          i) Storage of your preferences and settings: Settings that allow our
                          programs to function properly or retain your preferences over time may be
                          stored on your device.
                        </p>
                        <p>
                          ii) Login and identity verification: When you log into your personal
                          account, we store a unique identifier and login time in an encrypted
                          cookie on your device. This cookie allows you to navigate from one page of
                          our website to another without requiring you to log in again on each page.
                        </p>
                        <p>
                          iii) Security: We use cookies to detect fraud and potential misuse of our
                          website and services.
                        </p>
                        <p>
                          iv) Social media: Some of our web properties include social media cookies,
                          including those that allow users connected to the social media service to
                          share content through it.
                        </p>
                        <p>
                          v) Comments: The BORNTOPARTY website uses cookies that allow you to
                          provide comments on a web location.
                        </p>
                        <p>
                          We use both &quot;persistent&quot; and &quot;session&quot; cookies and
                          employ different types of cookies for the operation of the Website:
                        </p>
                        <p>
                          Essential cookies: We can use cookies to remember information that changes
                          the way our Website responds or appears, such as the language preferred by
                          a user of our Website.
                        </p>
                        <p>
                          Analytics cookies: We can use analytics cookies to process information
                          about how our Website is used in order to make improvements. Additionally,
                          we can use analytics cookies to test new advertisements, pages, displays,
                          or new aspects of the functionality of our Website to discern how users
                          respond to them.
                        </p>
                        <p>Third-party cookies</p>
                        <p>
                          In addition to our own cookies, we are able to use various third-party
                          cookies to report statistical usage information for the Website, display
                          advertisements on the Website, and through it, etc.
                        </p>
                        <p>
                          For example, Google Analytics collects information that allows us to
                          understand interactions with our websites, improve them, and ultimately
                          perfect the experience to better serve you.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="Options provided regarding the use of cookies:"
                    secondary={
                      <>
                        <p>
                          If you wish to delete cookies yourself or direct your browser to delete or
                          refuse the use of cookies, please visit your browser&apos;s help pages.
                        </p>
                        <p>
                          However, please note that if you proceed to delete cookies or refuse their
                          use, you may no longer have the ability to use the features offered by our
                          Website and to save your preferences, while some of our pages may not
                          fully respond.
                        </p>
                        <p>
                          For using the &quot;Chrome&quot; browser, please visit the following page
                          from Google:{' '}
                          <CustomLink link="https://support.google.com/accounts/answer/32050" />
                        </p>
                        <p>
                          For using the &quot;Internet Explorer&quot; browser, please visit the
                          following page from Microsoft:{' '}
                          <CustomLink link="https://support.microsoft.com/kb/278835" />
                        </p>
                        <p>
                          For using the &quot;Firefox&quot; browser, please visit the following page
                          from Mozilla:{' '}
                          <CustomLink link="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" />
                        </p>
                        <p>
                          For using the &quot;Safari&quot; browser, please visit the following page
                          from Apple:{' '}
                          <CustomLink link="https://support.apple.com/kb/PH21411?locale=en_US" />
                        </p>
                        <p>
                          For using any other browser, please visit the official websites of your
                          browser.
                        </p>
                      </>
                    }
                  />
                  <CustomListItemText
                    primary="Where you can find more information about cookies:"
                    secondary={
                      <>
                        <p>
                          You have the option to learn more about the use of cookies on the
                          following websites of other services:
                        </p>
                        <p>
                          AllAboutCookies: <CustomLink link="https://www.allaboutcookies.org/" />
                        </p>
                        <p>
                          Network Advertising Initiative:{' '}
                          <CustomLink link="https://www.networkadvertising.org/" />
                        </p>
                      </>
                    }
                  />
                </>
              }
            />
          </List>
        </Typography>
      </Container>
    </>
  );
}
