import Map, {
  GeoJSONSource,
  Layer,
  LayerProps,
  LngLatLike,
  MapLayerMouseEvent,
  MapRef, Marker,
  Source
} from 'react-map-gl'
import {IParty} from 'project-types'
import {Box, Typography} from '@mui/material'
import {RefObject, useEffect, useRef, useState} from 'react'
import {useTheme} from '@mui/material/styles'
import { distance, point } from '@turf/turf'
import {MAP_API} from '../../../config-global'
import {MapControl, MapMarker, MapPopup} from '../../theme-components/map'
import ToggleFiltersButton from './ToggleFiltersButton'
import PartyFilterDrawer from '../partyFilter/PartyFilterDrawer'
import TextLoading from '../../theme-components/animate/TextLoading'
import {MapCoordinates} from '../../../@types/party'
import mapUtils from './mapUtils'
import {PartyMarker} from './PartyMarker'

type PartyMapProps = {
    parties: IParty[],
    coordinates: MapCoordinates,
    mapRef: RefObject<MapRef>
    controls?: boolean
    filters?: boolean
    initialZoom?: number
    threeD?: boolean
    disableLoading?: boolean
    onViewPortChange?: ({ lat, lng, radius }: {lat: number, lng: number, radius: number}) => void
    onPartyMarkerClick?: (party: IParty) => void
}

const PartyMap = (props: PartyMapProps) => {
  const { parties, coordinates, mapRef, controls = true, filters = true, initialZoom = 10, threeD = false, disableLoading = false, onViewPortChange, onPartyMarkerClick } = props
  const [visiblePartyIds, setVisiblePartyIds] = useState<Set<string>>(new Set());
  const theme = useTheme()
  // Filters
  const [openFilter, setOpenFilter] = useState(false);
  // Map
  const [popupInfo, setPopupInfo] = useState<IParty | null>(null);
  const baseSettings = {
    mapboxAccessToken: MAP_API,
    minZoom: 1,
  };

  if (!coordinates && !disableLoading) return <Box sx={{
    mb: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  }}>
    <img
      src="/assets/icons/components/ic_extra_map.png"
      height="200"
      width="201"
      alt='location map icon'

    />
    <TextLoading text='Retrieving your location...' delay={1} duration={2} offsetMultiplier={0.02} />
  </Box>
  const coords = popupInfo && mapUtils.extractPartyCoordinates(popupInfo.location) ? mapUtils.extractPartyCoordinates(popupInfo.location) : null
  const initialState = {
    ...coordinates,
    zoom: initialZoom,
    bearing: 0,
    pitch: threeD ? 60 : 0,
  }

  /**
     * Cluster
     */
  const onClick = (event: MapLayerMouseEvent) => {
    const feature = event.features?.[0];
    if (!feature) {
      console.warn('This point does not contain a feature. Look at the interactiveLayers ids passed to map')
      return
    }
    const clusterId = feature?.properties?.cluster_id;
    const mapboxSource = mapRef.current?.getSource('parties') as GeoJSONSource;
    if (mapboxSource.getClusterExpansionZoom && feature.properties && feature.properties.cluster) {
      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        if (feature?.geometry.type === 'Point') {
          mapRef.current?.easeTo({
            center: feature?.geometry.coordinates as LngLatLike | undefined,
            zoom: Number.isNaN(zoom) ? 3 : zoom,
            duration: 500,
          });
        }
      });
    } else if (feature && feature.properties && feature.properties.id) {
      // const {id} = feature.properties
      // console.log('Clicked on a party', id)
      // const party = parties.find(x => x.id === id)
      // setPopupInfo(party || null)
    }
  };
  const data: any = {
    type: 'FeatureCollection',
    features: parties.map(party => {
      const partyCoordinates = mapUtils.extractPartyCoordinates(party.location)
      return {
        type: 'Feature',
        properties: {
          id: party.id,
          name: party.name
        },
        geometry: {
          type: 'Point',
          coordinates: partyCoordinates ? [ partyCoordinates.longitude, partyCoordinates.latitude ] : []
        }
      }
    })
  };
  const clusterLayer: LayerProps = {
    id: 'clusters',
    type: 'circle',
    source: 'parties',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': theme.palette.primary.main,
      'circle-radius': 20,
    }
  };
  const clusterCountLayer: LayerProps = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'parties',
    filter: ['has', 'point_count'],
    paint: {
      'text-color': theme.palette.primary.contrastText
    },
    layout: {
      'text-field': '🎉 {point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12
    }
  };
  const unclusteredPointLayer: LayerProps = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'parties',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': theme.palette.primary.main,
      'circle-radius': 6,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }
  };

  const getRadiusInKm = (map: mapboxgl.Map) => {
    const bounds = map.getBounds();

    const center = map.getCenter();
    const north = bounds.getNorth();
    const centerPoint = point([center.lng, center.lat]);
    const northPoint = point([center.lng, north]);
    const distanceInKm = distance(centerPoint, northPoint, { units: 'kilometers' });
    return distanceInKm;
  }

  // This is used to prevent labels showing on clusters by storing the ids of the visible parties
  const updateVisibleParties = () => {
    const features = mapRef.current?.querySourceFeatures('parties');
    const ids = new Set<string>();
    features?.forEach((feature) => {
      if (feature.properties && feature.properties.id) {
        ids.add(feature.properties.id);
      }
    });
    setVisiblePartyIds(ids);
  };

  return (
    <Map
      initialViewState={initialState}
      // mapStyle='mapbox://styles/mapbox/light-v10' // light
      mapStyle='mapbox://styles/mapbox/dark-v11'
      interactiveLayerIds={[clusterLayer.id || '', unclusteredPointLayer.id || '']}
      onClick={onClick}
      ref={mapRef}
      onIdle={() => {
        updateVisibleParties();
      }}
      onDragEnd={(event) => {
        const {target, viewState} = event
        if (onViewPortChange) {
          onViewPortChange({ lat: viewState.latitude, lng: viewState.longitude, radius: getRadiusInKm(target) })
        }
        updateVisibleParties();
      }}
      onZoomEnd={(event) => {
        const {target, viewState} = event
        if (onViewPortChange) {
          onViewPortChange({ lat: viewState.latitude, lng: viewState.longitude, radius: getRadiusInKm(target) })
        }
        updateVisibleParties();
      }}
      {...baseSettings}
    >
      <Source
        id="parties"
        type="geojson"
        data={data}
        cluster
        clusterMaxZoom={14}
        clusterRadius={50}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredPointLayer} />
      </Source>

      {parties.map(party => {
        const partyCoordinates = mapUtils.extractPartyCoordinates(party.location);
        if (!partyCoordinates || !visiblePartyIds.has(party.id)) return null;
        return (
          <Marker
            key={party.id}
            longitude={partyCoordinates.longitude}
            latitude={partyCoordinates.latitude}
            offset={[0, -30]}
          >
            <PartyMarker party={party} onClick={onPartyMarkerClick}/>
          </Marker>
        );
      })}

      { controls && <MapControl /> }
      {
        filters && <PartyFilterDrawer
          open={openFilter}
          onOpen={() => setOpenFilter(true)}
          onClose={() => setOpenFilter(false)}
          button={
            <ToggleFiltersButton
              onToggle={() => setOpenFilter(!openFilter)}
            />
          }
          buttonContainerStyle={{
            p: 0.5,
            right: 20,
            top: 24,
            zIndex: 999,
            position: 'absolute',
          }}
        />
      }

    </Map>
  )
}

export default PartyMap
