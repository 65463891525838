import { memo } from 'react';
// @mui
import { Box } from '@mui/material';
//
import { StyledRootScrollbar, StyledScrollbar } from './styles';
import { ScrollbarProps } from './types';

// ----------------------------------------------------------------------

function ScrollbarAllDevices({ children, sx, ...other }: ScrollbarProps) {
  return (
    <StyledRootScrollbar>
      <StyledScrollbar clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
}

export default memo(ScrollbarAllDevices);
