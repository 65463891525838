// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import {useEffect} from 'react'
import {debounce} from '@mui/material/utils'
import {LocationAutocomplete, LocationDetails} from 'project-types'
import {useDispatch, useSelector} from '../../../redux/store'
import {regenerateSession, setLoading} from '../../../redux/slices/locationSlicer'
import GeoThunks from '../../../redux/api/geo/GeoThunks'

// ----------------------------------------------------------------------

type Props = {
  name: string;
  label?: string;
  helperText?: React.ReactNode;
}

export default function RHFAutocompleteLocation(props: Props) {
  const {
    name,
    label,
    helperText,
    ...other
  } = props
  const { control, setValue, getValues } = useFormContext()

  const { location } = getValues()
  const { doorbell, floor } = location || {}

  const dispatch = useDispatch()
  // const { setValue } = useFormContext()
  const { results, session } = useSelector(state => state.locations)
  useEffect(() => {
    dispatch(regenerateSession());
  }, [dispatch]);

  const handleInputChange = debounce((value: string) => {
    if (value && session) {
      dispatch(setLoading(true));
      dispatch(GeoThunks.autocomplete({ search: value, sessionToken: session }));
    }
  }, 500);

  const handleChange = async (value: any) => {
    // console.log('Value selected', value)
    if (value) {
      dispatch(regenerateSession())
      const res = await dispatch(GeoThunks.getDetails({ placeId: value.placeId }))
      const locationDetails = (res?.payload as any)?.res as unknown as LocationDetails
      setValue(name, {...locationDetails, floor, doorbell}, { shouldValidate: true })
    } else {
      setValue(name, {floor, doorbell}, { shouldValidate: true })
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          {...other}
          onChange={async (event, newValue) => {
            await handleChange(newValue)
          }}
          renderInput={(params) => (
            <TextField
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          onInputChange={(event, value) => handleInputChange(value)}
          getOptionLabel={(e) => e.address || e.description || ''}
          isOptionEqualToValue={(a, b) => a && b && a.placeId === b.placeId}
          options={results}
        />
      )}
    />
  );
}
