import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
// config
import { HEADER } from '../../../config-global';
//
import Header from './Header';

// ----------------------------------------------------------------------
type CompactLayoutProps = {
  fullWidth?: boolean;
}
export default function CompactLayout(props: CompactLayoutProps) {
  const { fullWidth = false } = props;
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOffset={isOffset} />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: fullWidth ? '100%' : 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
