import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import {setupListeners} from '@reduxjs/toolkit/query'
import rootReducer, { rootPersistConfig } from './rootReducer';
import {notificationsApi} from './api/notifications/NotificationQuery'
import {statsApi} from './api/stats/StatsQuery'
import {imagesApi} from './api/images/ImagesQuery'

// ----------------------------------------------------------------------

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(notificationsApi.middleware)
      .concat(statsApi.middleware)
      .concat(imagesApi.middleware),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

const useDispatch = () => useAppDispatch<AppDispatch>();

export { store, persistor, dispatch, useSelector, useDispatch };

setupListeners(store.dispatch);
