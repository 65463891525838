import {Stack, Typography} from '@mui/material'
import {RHFAutocomplete} from '../../theme-components/hook-form'
import {MUSIC_GENRE} from '../../../config-global'

export default function MusicFilter () {
  return <Stack>
    <Typography variant="subtitle1" sx={{ flexGrow: 1, mb: 2 }}>
      Music
    </Typography>
    <RHFAutocomplete
      data-cy='party-music-filter'
      name="music"
      multiple
      freeSolo
      options={MUSIC_GENRE.map((option) => option)}
      ChipProps={{ size: 'small' }}
    />
  </Stack>
}
