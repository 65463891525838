// eslint-disable-next-line import/no-extraneous-dependencies
import {IPartyComment} from 'project-types'
import { ThunkGenerator } from '../ThunkGenerator'
import PartyCommentResource from './PartyCommentResource'

const PartyCommentThunks = new ThunkGenerator<IPartyComment>()
const resource = new PartyCommentResource()

export default {
  getMany: PartyCommentThunks.generateGetMany('PartyComments/getMany', (filters) => resource.getMany(filters)),
  save: PartyCommentThunks.generatePost('PartyComments/post', (resourceData) => resource.post(resourceData)),
  reply: PartyCommentThunks.generatePatch('PartyComments/reply', (updatePayload) => resource.reply(updatePayload.id, updatePayload.resource)),
  delete: PartyCommentThunks.generateDelete('PartyComments/delete', (commentID) => resource.delete(commentID))
}
