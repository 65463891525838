// form
import {useForm} from 'react-hook-form'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {
  Box,
  Stack,
  Badge,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
// config
import {NAV} from '../../../config-global'
// components
import Iconify from '../../theme-components/iconify'
import Scrollbar from '../../theme-components/scrollbar'
import FormProvider from '../../theme-components/hook-form'

import {RootState, useDispatch} from '../../../redux/store'
import {IPartyFilter} from '../../../redux/types'
import {setPartyFilters, START_DATE} from '../../../redux/slices/partySlicer'
import BoozeFilter from './BoozeFilter'
import MusicFilter from './MusicFilter'
import DateFilter from './DateFilter'


type Props = {
    open: boolean;
    onOpen: VoidFunction;
    onClose: VoidFunction;
    button?: React.ReactNode
    buttonContainerStyle?: any
};

export default function PartyFilterDrawer({open, onOpen, onClose, button, buttonContainerStyle = { width: '170px' }}: Props) {
  const dispatch = useDispatch()
  // Filters
  const { filters } = useSelector((state: RootState) => state.parties);
  const methods = useForm<IPartyFilter>({ defaultValues: filters });
  const { reset, watch } = methods;
  const formValues = watch()
  const filtersEnabled = Object.keys(filters).filter((key) => {
    const val = filters[key as keyof IPartyFilter]
    if (key === 'startDate' && val && val instanceof Date) {
      return val.toISOString() !== START_DATE.toISOString()
    }
    if (Array.isArray(val)) return val.length > 0
    if (val === '') return false
    return val != null;
  }).length

  const defaultFilterValues = {
    booze: [],
    capacity: [],
    entrance: [],
    rating: '',
    startDate: null,
    endDate: null,
    search: '',
    music: []
  }

  // Use effect to update filters in REDUX
  useEffect(() => {
    const filtersChanged = JSON.stringify(formValues) !== JSON.stringify(filters)
    if (filtersChanged) dispatch(setPartyFilters(formValues))
  }, [formValues, filters, dispatch])

  const defaultButton = <Button
    disableRipple
    color="inherit"
    endIcon={<Iconify icon="ic:round-filter-list" />}
    onClick={onOpen}
  >
    Filters
  </Button>

  return (
    <FormProvider methods={methods}>
      <Box sx={{ ...buttonContainerStyle, display: 'flex', justifyContent: 'center', mt: '10px' }} >
        <Badge
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          color="warning"
          variant="standard"
          showZero={false}
          badgeContent={filtersEnabled}
          sx={{ mx: 'auto' }}
        >
          {button !== undefined ? button : defaultButton}
        </Badge>
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        BackdropProps={{invisible: true}}
        PaperProps={{sx: { width: NAV.W_BASE },}}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2, pr: 1, py: 2 }}
        >
          <Typography variant="subtitle1">Filters</Typography>

          <IconButton data-cy='close-party-filters' onClick={onClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 2.5 }}>
            <DateFilter/>
            <BoozeFilter/>
            {/* <CapacityFilter/> */}
            {/* <EntranceFilter slider={false} /> */}
            <MusicFilter/>
            {/* <LiveRatingFilter/> */}
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 2.5 }}>
          <Badge
            color="error"
            variant="dot"
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            invisible={filtersEnabled === 0}
            sx={{ width: 1 }}
          >
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={() => reset(defaultFilterValues)}
              startIcon={<Iconify icon="eva:trash-2-outline" />}
              data-cy='clear-party-filters'
            >
              Clear
            </Button>
          </Badge>
        </Box>
      </Drawer>
    </FormProvider>
  );
}
