import React from 'react'
import {IParty} from 'project-types'
import {useNavigate} from 'react-router-dom'
import {Box, Typography} from '@mui/material'
import {CustomAvatar} from '../../theme-components/custom-avatar'
import {truncateText} from '../../../utils/truncateText'

export const PartyMarker = ({ party, onClick }: { party: IParty, onClick?: (party: IParty) => void }) => {
  const {user} = party
  return <Box 
    onClick={() => onClick && onClick(party)}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      padding: '2px',
      paddingRight: '15px',
      borderRadius: '15px',
    }}>
    {user && <CustomAvatar
      // onClick={() => navigate(`/users/${user.id}`)}
      src={user.profileImage}
      alt={user.name}
      name={user.name}
      sx={{
        border: '2px solid white',
        width: '20px',
        height: '20px',
        marginRight: '5px'
      }}
    /> }
    <Typography sx={{ color: 'black', fontSize: '10px'}}>
      {truncateText(party.name, 100)}
    </Typography>
  </Box>
};
