import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import ManagePartyThunkReducers from '../api/parties/ManagePartyThunkReducers'
import {IPartyFilter, PartiesState} from '../types'

export const initialState: PartiesState = {
  party: null,
  parties: [],
  error: undefined,
  loading: false,
  count: 0,
  pagination: {
    page: 1,
    pageSize: 10,
    filtered: 10
  },
  order: {
    order: 'date',
    orderDirection: 'desc'
  },
  filters: {
    booze: [],
    capacity: [],
    entrance: [],
    rating: '',
    startDate: null,
    endDate: null,
    search: '',
    music: []
  },
  aggregations: {
    loading: false,
    total: 0,
    status: []
  }
}

export const managePartySlicer = createSlice({
  name: 'manageParties',
  initialState,
  reducers: {
    setLoading: (state: PartiesState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setPartyFilters: (state: PartiesState, { payload }: PayloadAction<IPartyFilter>) => {
      state.filters = payload
    },
    setPaginationPage: (state: PartiesState, { payload }: PayloadAction<number>) => {
      state.pagination.page = payload
    },
    setPaginationPageSize: (state: PartiesState, { payload }: PayloadAction<number>) => {
      state.pagination.pageSize = payload
    },
    setOrderField: (state: PartiesState, { payload }: PayloadAction<string>) => {
      state.order.order = payload
    },
    setOrderDirection: (state: PartiesState, { payload }: PayloadAction<'asc' | 'desc' | undefined>) => {
      state.order.orderDirection = payload
    },
    clearParty: (state: PartiesState) => {
      state.party = null
    },
    clearParties: (state: PartiesState) => {
      state.parties = []
    },
  },
  extraReducers: ManagePartyThunkReducers
})

export const {
  setLoading,
  setPartyFilters,
  setOrderField,
  setPaginationPageSize,
  setOrderDirection,
  setPaginationPage,
  clearParty,
  clearParties
} = managePartySlicer.actions
export default managePartySlicer.reducer
