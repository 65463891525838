import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Stack, Typography, Link} from '@mui/material';
// routes
import {PATH_AUTH} from '../../routes/paths';
// layouts
import LoginLayout from '../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithGoogle from './AuthWithGoogle';
import Logo from '../theme-components/logo'
import './Login.css'

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{mb: 15, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Logo
          className='balloon-logo'
          sx={{
            zIndex: 9,
            // position: 'absolute',
            width: '100px',
            height: '100px'
          }}
        />
      </Stack>
      <Stack spacing={2} sx={{mb: 5, position: 'relative'}}>
        <Typography variant="h4">Sign in</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            Create an account
          </Link>
        </Stack>

      </Stack>

      {/* <AuthLoginForm /> */}

      <AuthWithGoogle/>
      <Typography
        component="div"
        sx={{color: 'text.secondary', mt: 3, typography: 'caption', textAlign: 'center'}}
      >
        {'By logging in, I agree to '}
        <Link underline="always" color="text.primary" to={PATH_AUTH.TOS} component={RouterLink}>
          Terms of Service
        </Link>
        {' and '}
        <Link underline="always" color="text.primary" to={PATH_AUTH.PP} component={RouterLink}>
          Privacy Policy
        </Link>
        .
      </Typography>
    </LoginLayout>
  );
}
